import { Resource, ResourceUnit } from "./Resource";
import { ResourcePriceCalculationType, ResourcePriceVariantsType, ResourceVariantIdentifier } from "./ResourcePrice";
import { PriceStructureType } from "./services/ServicePriceBlocks";

export class LiquidMixture {
    public id: string;
    public name: string;
    public components: MixtureComponent[];
    public amountPerHa: number;

    constructor(initialValues?: Partial<LiquidMixture>) {
        this.id = initialValues?.id ?? "";
        this.name = initialValues?.name ?? "Mischung";
        this.components = initialValues?.components ?? [];
        this.amountPerHa = initialValues?.amountPerHa ?? 0;
    }
}

export interface MixtureComponent {
    id: string;
    resource: ResourceVariantIdentifier;
    concentration: number;
}

export const WATER_FOR_LIQUID_MIXTURES_ID = "WATER_FOR_LIQUID_MIXTURES_ID";
export const WATER_FOR_LIQUID_MIXTURES_PRICE_ID = "WATER_FOR_LIQUID_MIXTURES_PRICE_ID";

export const WATER_FOR_LIQUID_MIXTURES = new Resource({
    id: WATER_FOR_LIQUID_MIXTURES_ID,
    name: "Wasser",
    unit: ResourceUnit.LITER,
    price: {
        variantsType: ResourcePriceVariantsType.SIMPLE,
        id: WATER_FOR_LIQUID_MIXTURES_PRICE_ID,
        costPerUnit: 0,
        pricePerUnit: { price: 0, type: PriceStructureType.SIMPLE },
        calculationType: ResourcePriceCalculationType.FIXED,
        name: "Wasser",
    },
});
