import { ResourceCategory, ResourceUnit } from "../Resource";
import { ChangeHistoryEntry } from "./ChangeHistoryEntry";

export class ResourceInventoryHistoryEntry extends ChangeHistoryEntry {
    previousInventoryCount: number;
    newValue: number;

    constructor(initialData?: Partial<ResourceInventoryHistoryEntry>) {
        super(initialData);
        this.previousInventoryCount = initialData?.previousInventoryCount ?? 0;
        this.newValue = initialData?.newValue ?? 0;
    }
}

export class ComplexResourceInventoryHistoryEntry extends ResourceInventoryHistoryEntry {
    resourceName: string;
    resourceType: ResourceUnit;
    resourceCategory: ResourceCategory;

    constructor(initialData?: Partial<ComplexResourceInventoryHistoryEntry>) {
        super(initialData);
        this.resourceName = initialData?.resourceName ?? "";
        this.resourceType = initialData?.resourceType ?? ResourceUnit.PIECE;
        this.resourceCategory = initialData?.resourceCategory ?? ResourceCategory.OTHER;
    }
}
