import { getDocs, Query } from "firebase/firestore";
import { FIREBASE_CONSTANTS } from "./constants";

export function getChunkedQueries<Constraint, Model>(
    constraints: Constraint[],
    queryGenerator: (constraints: Constraint[]) => Query<Model>,
    chunkSize = FIREBASE_CONSTANTS.LIMIT_DISJUNCTIVE_CLAUSES
): Query<Model>[] {
    const chunksCount = Math.ceil(constraints.length / chunkSize);

    const queries: Query<Model>[] = [];
    for (let i = 0; i < chunksCount; i++) {
        const from = i * chunkSize;
        const to = (i + 1) * chunkSize;

        const constraintGroup = constraints.slice(from, to);
        queries.push(queryGenerator(constraintGroup));
    }

    return queries;
}

/**
 * Merge mutliple queries together. Useful for `where("id", "in", ...)` queries with many constraints.
 * Firestore only supports up to 10 constraints per where-in.
 * @see https://firebase.google.com/docs/firestore/query-data/queries#in_not-in_and_array-contains-any
 * @param constraints List of where clauses
 * @param queryGenerator Callback invoked for each constraints group
 * @returns
 */
export async function mergeQueryData<Constraint, Model>(
    constraints: Constraint[],
    queryGenerator: (constraints: Constraint[]) => Query<Model>
): Promise<Model[]> {
    const queries = getChunkedQueries(constraints, queryGenerator);

    const operations = queries.map(query => {
        return getDocs(query);
    });

    const results = await Promise.allSettled(operations);
    return results.reduce<Model[]>((acc, cur) => {
        if (cur.status === "rejected") {
            return acc;
        }

        return [...acc, ...cur.value.docs.map(doc => doc.data())];
    }, []);
}
