import { Firebase } from "./index";
import { WriteBatch } from "firebase/firestore";

const BATCH_WRITE_LIMIT = 500;
// if many data records are to be written independently, use lower batch size to increase perceived performance
const BOOSTED_BATCH_WRITE_LIMIT = 50;

/**
 * Savely perform unlimited batched Firestore writes
 */
export class ClientBatchedWrite {
    private batches: WriteBatch[] = [];
    private boosted: boolean = false;

    private writeCounter = 0;
    private currentBatch?: WriteBatch;

    /**
     * @param boosted set to false if writes do depend on each other
     */
    constructor(boosted = true) {
        this.boosted = boosted;
    }

    /**
     * Request a `WriteBatch`. Make sure to only do one write call per `batch()`
     * @returns
     */
    batch() {
        if (!this.currentBatch) {
            this.currentBatch = Firebase.instance().createWriteBatch();
            this.writeCounter = 0;
        } else {
            if (this.writeCounter >= (this.boosted ? BOOSTED_BATCH_WRITE_LIMIT : BATCH_WRITE_LIMIT)) {
                this.batches.push(this.currentBatch);
                this.currentBatch = Firebase.instance().createWriteBatch();
                this.writeCounter = 0;
            }
        }

        this.writeCounter += 1;
        return this.currentBatch!;
    }

    /**
     * Commit all open batches
     * @returns
     */
    async commit() {
        const batchesToCommit = this.batches;

        if (this.currentBatch) {
            batchesToCommit.push(this.currentBatch);
        }

        const batchResults = await Promise.all(batchesToCommit.map(batch => batch.commit()));

        this.batches = [];
        this.currentBatch = undefined;
        this.writeCounter = 0;

        return batchResults;
    }
}
