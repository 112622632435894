import { Capacitor } from "@capacitor/core";
import * as Routes from "@farmact/model/src/constants/farmActAppRoutes";
import { Absence } from "@farmact/model/src/model/Absence";
import { AlertNotification, OrderNotificationType } from "@farmact/model/src/model/AlertNotification";
import { AppCompany } from "@farmact/model/src/model/AppCompany";
import { AppMeta } from "@farmact/model/src/model/AppMeta";
import { AppUser, CustomAppUserClaims } from "@farmact/model/src/model/AppUser";
import { AreaMeasurement } from "@farmact/model/src/model/AreaMeasurement";
import { Bill } from "@farmact/model/src/model/Bill";
import { BillReminder } from "@farmact/model/src/model/BillReminder";
import {
    BillChangeCategory,
    BillChangeHistoryEntry,
} from "@farmact/model/src/model/changeHistory/BillChangeHistoryEntry";
import {
    ComplexResourceInventoryHistoryEntry,
    ResourceInventoryHistoryEntry,
} from "@farmact/model/src/model/changeHistory/ResourceInventoryHistoryEntry";
import { CreditNote } from "@farmact/model/src/model/CreditNote";
import { Customer, preparePartialCustomerForFirestore } from "@farmact/model/src/model/Customer";
import { DeliveryNote, DeliveryNoteStatus } from "@farmact/model/src/model/DeliveryNote";
import { Activity, ActivityType, Employee } from "@farmact/model/src/model/Employee";
import { EmployeeLocation } from "@farmact/model/src/model/EmployeeLocation";
import Field from "@farmact/model/src/model/Field";
import { constructFieldMetaUpdateFromFieldUpdate, FieldMeta } from "@farmact/model/src/model/FieldMeta";
import {
    BaseSharingRequest,
    FirebaseUpdateCustomerPositionForCustomerRequest,
    GetFieldsByIdsForCustomerRequest,
    GetTracksByIdsForCustomerRequest,
    InsertFieldMarkersForCustomerRequest,
    InsertFieldsForCustomerRequest,
    InsertLoadingOrUnloadingPointsForCustomerRequest,
    InsertMarkersForCustomerRequest,
    InsertTracksForCustomerRequest,
    UpdateFieldForCustomerRequest,
    UpdateLoadingOrUnloadingPointForCustomerRequest,
    UpdateMarkerForCustomerRequest,
    UpdateTrackForCustomerRequest,
} from "@farmact/model/src/model/functions/sharingFunctionTypes";
import { GasStation } from "@farmact/model/src/model/GasStation";
import { GeolocationTracking, VertexWithTime } from "@farmact/model/src/model/GeolocationTracking";
import { LiquidMixture } from "@farmact/model/src/model/LiquidMixture";
import { LoadingOrUnloadingPoint } from "@farmact/model/src/model/LoadingOrUnloadingPoint";
import { Machine } from "@farmact/model/src/model/Machine";
import { MachineCounterTracking, MachineCounterType } from "@farmact/model/src/model/MachineCounterTracking";
import { Marker, MarkerType } from "@farmact/model/src/model/Marker";
import { Offer } from "@farmact/model/src/model/Offer";
import { OperatingUnit } from "@farmact/model/src/model/OperatingUnit";
import {
    ActiveTime,
    IdentifiablePhoto,
    Order,
    OrderWithoutOrderNumber,
    preparePartialOrderForFirestore,
} from "@farmact/model/src/model/Order";
import { OrderStatus } from "@farmact/model/src/model/OrderStatus";
import { Overtime } from "@farmact/model/src/model/Overtime";
import { PhotoSize } from "@farmact/model/src/model/PhotoSize";
import { Refuel } from "@farmact/model/src/model/Refuel";
import {
    AnyRentalOrderPriceTracking,
    isRentalOrder,
    RentalOrder,
    RentalOrderStatus,
} from "@farmact/model/src/model/RentalOrder";
import { Resource } from "@farmact/model/src/model/Resource";
import { Role } from "@farmact/model/src/model/Role";
import { SharingToken, SharingTokenType } from "@farmact/model/src/model/SharingToken";
import { TimeTracking, TimeTrackingField } from "@farmact/model/src/model/TimeTracking";
import { Track } from "@farmact/model/src/model/Track";
import { constructTrackMetaUpdateFromTrackUpdate, TrackMeta } from "@farmact/model/src/model/TrackMeta";
import { Service } from "@farmact/model/src/model/services/Service";
import { OrderWorkType } from "@farmact/model/src/model/workTypes/OrderWorkType";
import { computeDates } from "@farmact/model/src/utils/computeDates";
import { constructLogoStoragePath } from "@farmact/model/src/utils/constructLogoStoragePath";
import { RecipientsService } from "@farmact/notification-app-client";
import dayjs, { Dayjs } from "dayjs";
import { initializeApp } from "firebase/app";
import {
    Auth,
    connectAuthEmulator,
    EmailAuthProvider,
    getAuth,
    indexedDBLocalPersistence,
    initializeAuth,
    ParsedToken,
    reauthenticateWithCredential,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    updatePassword,
    User,
    UserCredential,
} from "firebase/auth";
import {
    arrayRemove,
    arrayUnion,
    connectFirestoreEmulator,
    deleteDoc,
    doc,
    endBefore,
    Firestore,
    getDoc,
    getDocs,
    getFirestore,
    increment,
    initializeFirestore,
    limit,
    orderBy,
    Query,
    query,
    QueryConstraint,
    setDoc,
    terminate,
    Transaction,
    UpdateData,
    updateDoc,
    where,
    WriteBatch,
    writeBatch,
} from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import {
    connectStorageEmulator,
    deleteObject,
    FirebaseStorage,
    getBlob,
    getDownloadURL,
    getMetadata,
    getStorage,
    listAll,
    ref,
    StorageError,
    uploadBytesResumable,
    UploadMetadata,
    UploadResult,
} from "firebase/storage";
import { firebaseConfig, localAddress } from "../../config/config";
import {
    AbsoluteResourceInventoryChange,
    RelativeResourceInventoryChange,
} from "../components/organization/resources/ResourcesTable/EditInventoryModal/EditInventoryModal";
import { ResourceInventoryEditMode } from "../components/organization/resources/ResourcesTable/EditInventoryModal/ResourceInventoryEditModeEnums/ResourceInventoryEditModeEnums";
import { getFileExtension } from "../util/fileUtils";
import { endActiveTime, startActiveTime } from "../util/orderUtils";
import { recordError } from "../util/recordError";
import { createRentalOrderPriceTrackings } from "../util/rentalOrderUtils";
import { getPhotoPath, getResizedPhotosPath } from "@farmact/pdf/src/storagePhotos";
import { DateLike } from "../util/timeUtils";
import { FirestoreCollections } from "./collections";
import { FirebaseFunctions } from "./functions/FirebaseFunctions";
import { mergeQueryData } from "./helpers";
import { getModelConverter } from "./ModelConverter";
import { MachineMaintenanceRecord } from "@farmact/model/src/model/MachineMaintenanceRecord";
import { Project } from "@farmact/model/src/model/Project";
import {
    ArchiveOperatingUnitRequest,
    CreateNewOperatingUnitRequest,
    SetAllCustomersMaschinenringSettingRequest,
} from "@farmact/model/src/model/functions/webAppFunctionTypes";
import { PUSH_TOKEN_LOCAL_STORAGE_KEY } from "../constants/localStorageKeys";
import { generateTimeTrackingName } from "../util/timeTrackingUtils";
import rfdc from "rfdc";

type FirebaseInitOptions = {
    runningInEmulator: boolean;
    runningInTests: boolean;
};

class Firebase {
    private static INSTANCE?: Firebase;
    public static initialize: (options?: FirebaseInitOptions) => void = options => {
        if (!Firebase.INSTANCE) {
            Firebase.INSTANCE = new Firebase(options);
        } else {
            console.log("Firebase already initialized.");
        }
    };

    public static instance = (options?: FirebaseInitOptions) => {
        if (!Firebase.INSTANCE) {
            Firebase.initialize(options);
        }
        return Firebase.INSTANCE!;
    };

    public auth: () => Auth;
    public firestore: () => Firestore;
    private functions: FirebaseFunctions;
    private storage: () => FirebaseStorage;
    public collections: FirestoreCollections;
    public ready: boolean = false;

    private usersCompanyId: string | undefined = undefined;

    private constructor(options?: FirebaseInitOptions) {
        const firebaseApp = initializeApp(firebaseConfig);
        this.firestore = () => getFirestore(firebaseApp);
        if (Capacitor.isNativePlatform()) {
            // without this, the onAuthStateChangeHandler won't fire on iOS apps
            // see https://forum.ionicframework.com/t/firebase-auth-in-sdk-9-does-not-work-on-ios-sim-or-devices/215362/3
            initializeAuth(firebaseApp, {
                persistence: indexedDBLocalPersistence,
            });
        }
        this.auth = () => getAuth(firebaseApp);
        const functionsInstance = getFunctions(firebaseApp, "europe-west3");
        this.functions = new FirebaseFunctions({ functions: functionsInstance });
        this.storage = () => getStorage(firebaseApp);

        initializeFirestore(firebaseApp, { ignoreUndefinedProperties: true });
        if (options?.runningInEmulator) {
            // use firestore emulator (needs to be running!)
            connectFirestoreEmulator(this.firestore(), localAddress, 8080);
            connectAuthEmulator(this.auth(), `http://${localAddress}:9099/`);
            connectFunctionsEmulator(functionsInstance, localAddress, 5001);
            connectStorageEmulator(this.storage(), localAddress, 9199);
        }
        this.collections = new FirestoreCollections(this.firestore());
    }

    public resetFirestore = async () => {
        await terminate(this.firestore());
        // TODO: this needs to be reenabled if we reintroduce persistence
        // await clearIndexedDbPersistence(this.firestore());
        // TODO: this needs to be reenabled if we change the local firestore member back to an object again
        // this.firestore = firebase.app().firestore();
    };

    public async reflectAuthChange(updatedUser: User | null) {
        if (updatedUser === null) {
            this.usersCompanyId = undefined;
            this.ready = false;
            return;
        }
        const claims = (await updatedUser?.getIdTokenResult())?.claims as
            | (ParsedToken & Partial<CustomAppUserClaims>)
            | undefined;
        if (!claims) {
            recordError("Could not determine claims even though auth user is present", {
                uid: this.getCurrentAuthUserUid(),
                claims,
            });
            return;
        }
        const companyId = claims?.defaultCompanyId;
        // When logged in as customer, companyId is not set
        if (!companyId) {
            const roles = Object.values(claims.roles ?? {});
            if (roles.length > 0 && !roles.includes(Role.CUSTOMER)) {
                recordError("Neither default company id present nor customer.", {
                    user: this.getCurrentAuthUserUid(),
                    claims,
                });
            }
        }
        this.usersCompanyId = companyId ?? undefined;
        this.ready = true;
    }

    public forceUpdateUsersCompanyId(newCompanyId: string) {
        this.usersCompanyId = newCompanyId;
    }

    /**
     * @internal
     * only for testing
     */
    public getCompanyId() {
        return this.usersCompanyId;
    }

    // *** Functions API ***
    public async inviteEmployee(inviteeMail: string, role: Role, employeeId: string) {
        return this.functions.inviteEmployee(inviteeMail, role, employeeId);
    }

    public async inviteCustomer(inviteeMail: string, customerId: string) {
        return this.functions.inviteCustomer(inviteeMail, customerId, this.usersCompanyId);
    }

    public acceptInvitation = async (invitationId: string) => {
        const response = await this.functions.acceptInvitation(invitationId);
        this.usersCompanyId = response.data.newCompanyId;
        return response;
    };

    public acceptMailInvitation = async (mailInvitationId: string, password: string) => {
        const response = await this.functions.acceptMailInvitation(mailInvitationId, password);
        this.usersCompanyId = response.data.newCompanyId;
        return response;
    };

    public rejectInvitation = (invitationId: string) => {
        return this.functions.rejectInvitation(invitationId);
    };

    public cancelInvitation(invitedEmployeeId: string) {
        return this.functions.cancelInvitation(invitedEmployeeId, this.usersCompanyId);
    }

    public cancelCustomerInvitation(invitedCustomerId: string) {
        return this.functions.cancelCustomerInvitation(invitedCustomerId, this.usersCompanyId);
    }

    public kickEmployee(employeeId: string) {
        return this.functions.kickEmployee(employeeId, this.usersCompanyId);
    }

    public kickCustomer(customerId: string) {
        if (!this.usersCompanyId) {
            recordError("Could not kick customer because company id was not set.", { customerId });
            return;
        }
        return this.functions.kickCustomer(customerId, this.usersCompanyId);
    }

    public deleteAccount = (userId: string) => {
        return this.functions.deleteAccount(userId);
    };

    public updateEmployeeRole(appUserId: string, employeeId: string, newRole: Role) {
        return this.functions.updateEmployeeRole(appUserId, employeeId, newRole, this.usersCompanyId);
    }

    public activateEmployee(appUserId: string, employeeId: string) {
        if (!this.usersCompanyId) {
            throw new Error("companyId not set");
        }

        return this.functions.changeEmployeeActiveState({
            appUserId,
            employeeId,
            companyId: this.usersCompanyId,
            active: true,
        });
    }

    public deactivateEmployee(appUserId: string, employeeId: string) {
        if (!this.usersCompanyId) {
            throw new Error("companyId not set");
        }

        return this.functions.changeEmployeeActiveState({
            appUserId,
            employeeId,
            companyId: this.usersCompanyId,
            active: false,
        });
    }

    public toggleCheckedOrders(enableCheckedOrderStatus: boolean) {
        return this.functions.toggleCheckedOrders({
            enableCheckedOrderStatus,
        });
    }

    public setupCheckoutSession = () => {
        return this.functions.setupCheckoutSession(this.usersCompanyId);
    };

    public setupCustomerPortalSession = () => {
        return this.functions.setupCustomerPortalSession(this.usersCompanyId);
    };

    public setAllCustomersMaschinenringSetting(request: SetAllCustomersMaschinenringSettingRequest) {
        return this.functions.setAllCustomersMaschinenringSetting(request);
    }

    public createNewOperatingUnit(request: CreateNewOperatingUnitRequest) {
        return this.functions.createNewOperatingUnit(request);
    }

    public archiveOperatingUnit(request: ArchiveOperatingUnitRequest) {
        return this.functions.archiveOperatingUnit(request);
    }

    public getCurrentlyActiveOrderGeolocationTrackingsForCustomer(requestData: BaseSharingRequest) {
        return this.functions.getCurrentlyActiveOrderGeolocationTrackingsForCustomer(requestData);
    }

    public getCurrentlyActiveEmployeeLocationsForCustomer(requestData: BaseSharingRequest) {
        return this.functions.getCurrentlyActiveEmployeeLocationsForCustomer(requestData);
    }

    public getFieldsDataForCustomer(requestData: BaseSharingRequest) {
        return this.functions.getFieldsDataForCustomer(requestData);
    }

    public getFieldsByIdsForCustomer(requestData: GetFieldsByIdsForCustomerRequest) {
        return this.functions.getFieldsByIdsForCustomer(requestData);
    }

    public getTracksByIdsForCustomer(requestData: GetTracksByIdsForCustomerRequest) {
        return this.functions.getTracksByIdsForCustomer(requestData);
    }

    public updateCustomerPositionForCustomer(requestData: FirebaseUpdateCustomerPositionForCustomerRequest) {
        const { customerId, ...restRequestData } = requestData;
        return this.functions.updateCustomerPositionForCustomer({
            ...restRequestData,
            customerIdToChange: customerId,
        });
    }

    public updateFieldForCustomer(requestData: UpdateFieldForCustomerRequest) {
        return this.functions.updateFieldForCustomer(requestData);
    }

    public updateTrackForCustomer(requestData: UpdateTrackForCustomerRequest) {
        return this.functions.updateTrackForCustomer(requestData);
    }

    public updateMarkerForCustomer(requestData: UpdateMarkerForCustomerRequest) {
        return this.functions.updateMarkerForCustomer(requestData);
    }

    public updateLoadingOrUnloadingPointForCustomer(requestData: UpdateLoadingOrUnloadingPointForCustomerRequest) {
        return this.functions.updateLoadingOrUnloadingPointForCustomer(requestData);
    }

    public insertFieldsForCustomer(requestData: InsertFieldsForCustomerRequest) {
        return this.functions.insertFieldsForCustomer(requestData);
    }

    public insertTracksForCustomer(requestData: InsertTracksForCustomerRequest) {
        return this.functions.insertTracksForCustomer(requestData);
    }

    public insertCustomerMarkersForCustomer(requestData: InsertMarkersForCustomerRequest) {
        return this.functions.insertCustomerMarkersForCustomer(requestData);
    }

    public insertFieldMarkersForCustomer(requestData: InsertFieldMarkersForCustomerRequest) {
        return this.functions.insertFieldMarkersForCustomer(requestData);
    }

    public insertLoadingOrUnloadingPointsForCustomer(requestData: InsertLoadingOrUnloadingPointsForCustomerRequest) {
        return this.functions.insertLoadingOrUnloadingPointsForCustomer(requestData);
    }

    // *** Auth API ***
    public async doSignInWithEmailAndPassword(email: string, password: string): Promise<UserCredential> {
        const credentials = await signInWithEmailAndPassword(this.auth(), email, password);
        if (!credentials.user?.uid) {
            throw new Error("Login failed.");
        }

        return credentials;
    }

    public getCurrentAuthUserUid() {
        return this.auth().currentUser?.uid;
    }

    public doSignOut = async () => {
        const pushToken = localStorage.getItem(PUSH_TOKEN_LOCAL_STORAGE_KEY);
        if (pushToken) {
            await RecipientsService.unregisterRecipient({ requestBody: { registrationToken: pushToken } });
        }
        await this.resetFirestore();
        // TODO: find better way to not spam bugsnag. Find a way so app does not throw an error at all.
        window.disableBugsnag = true;
        await this.auth().signOut();
    };

    public doPasswordReset = (email: string) => sendPasswordResetEmail(this.auth(), email);
    public doPasswordUpdate = (password: string) => {
        const currentUser = this.auth().currentUser;
        if (currentUser) {
            return updatePassword(currentUser, password);
        }
    };

    public doReauthenticate = (password: string) => {
        const currentUser = this.auth().currentUser;
        if (!currentUser) {
            return;
        }
        return reauthenticateWithCredential(
            currentUser,
            EmailAuthProvider.credential(currentUser.email ?? "notLoggedIn", password)
        );
    };

    /************************
     *     Firestore API    *
     ************************/
    public createWriteBatch() {
        return writeBatch(this.firestore());
    }

    public getCompanyMeta(companyId: string | undefined) {
        return doc(this.collections.companiesMeta(), companyId ?? this.usersCompanyId);
    }

    public getAppUserMeta(authUserId: string | undefined) {
        return doc(this.collections.usersMeta(), authUserId ?? this.getCurrentAuthUserUid() ?? "");
    }

    public getAllAppCompanies(includeArchived?: boolean) {
        const allCompaniesRef = this.collections.appCompanies();
        return includeArchived ? allCompaniesRef : query(allCompaniesRef, where("archived", "==", false));
    }

    public getAllOperatingUnits(includeArchived?: boolean) {
        const allUnitsRef = this.collections.operatingUnits(this.usersCompanyId);
        return includeArchived ? allUnitsRef : query(allUnitsRef, where("archived", "==", false));
    }

    public getOperatingUnitRef(unitId: string, companyId?: string) {
        return doc(this.collections.operatingUnits(companyId ?? this.usersCompanyId), unitId);
    }

    public getAppCompanyRef(companyId?: string) {
        return doc(this.collections.appCompanies(), companyId ?? this.usersCompanyId);
    }

    public updatePartialAppCompany(companyId: string, updatedValues: UpdateData<AppCompany>) {
        return updateDoc(this.getAppCompanyRef(companyId), updatedValues);
    }

    public updatePartialOperatingUnit(unitId: string, updatedValues: UpdateData<OperatingUnit>): Promise<void>;
    public updatePartialOperatingUnit(
        unitId: string,
        updatedValues: UpdateData<OperatingUnit>,
        options: { transaction: Transaction }
    ): void;
    public updatePartialOperatingUnit(
        unitId: string,
        updatedValues: UpdateData<OperatingUnit>,
        options: { batch: WriteBatch }
    ): void;
    public updatePartialOperatingUnit(
        unitId: string,
        updatedValues: UpdateData<OperatingUnit>,
        options?: {
            transaction?: Transaction;
            batch?: WriteBatch;
        }
    ) {
        if (options?.transaction) {
            options.transaction.update(this.getOperatingUnitRef(unitId), updatedValues);
        } else if (options?.batch) {
            options.batch.update(this.getOperatingUnitRef(unitId), updatedValues);
        } else {
            return updateDoc(this.getOperatingUnitRef(unitId), updatedValues);
        }
    }

    public updateWholeOperatingUnit(operatingUnit: OperatingUnit) {
        return setDoc(this.getOperatingUnitRef(operatingUnit.id), operatingUnit);
    }

    public updateWholeAppCompany(company: AppCompany) {
        return setDoc(this.getAppCompanyRef(company.id), company);
    }

    public async updateOperatingUnitLogo(
        operatingUnitId: string,
        logo: File,
        onProgressPercentChange?: (percent: number) => void
    ) {
        if (!this.usersCompanyId) {
            return Promise.reject();
        }
        const logoPath = constructLogoStoragePath(this.usersCompanyId, operatingUnitId, getFileExtension(logo));
        const uploadedLogo = await this.uploadAppendix(logoPath, logo, onProgressPercentChange, {
            contentType: logo.type,
        });
        const updatedLogo: IdentifiablePhoto = {
            storagePath: logoPath,
            imageSrc: await getDownloadURL(uploadedLogo.ref),
        };
        await this.updatePartialOperatingUnit(operatingUnitId, {
            logo: updatedLogo,
        });
        return updatedLogo;
    }

    public getAllDatevConnections() {
        return this.collections.datevConnections(this.usersCompanyId);
    }

    public getAllActiveDatevConnections() {
        return query(this.getAllDatevConnections(), where("status", "in", ["CONNECTED_ACTIVE", "CONNECTED_INACTIVE"]));
    }

    public getDatevConnectionRefForOperatingUnit(operatingUnitId: OperatingUnit["id"]) {
        return doc(this.collections.datevConnections(this.usersCompanyId), operatingUnitId);
    }

    public async updateEmployeePhoto(
        employeeId: string,
        photo: File,
        onProgressPercentChange?: (percent: number) => void
    ) {
        if (!this.usersCompanyId) {
            return Promise.reject();
        }
        const photoPath = `companies/${this.usersCompanyId}/employees/${employeeId}/photo.${getFileExtension(photo)}`;
        const uploadedPhoto = await this.uploadAppendix(photoPath, photo, onProgressPercentChange, {
            contentType: photo.type,
        });
        const updatedPhoto = {
            storagePath: photoPath,
            imageSrc: await getDownloadURL(uploadedPhoto.ref),
        };
        await this.updateEmployee(employeeId, {
            photo: updatedPhoto,
        });
        return updatedPhoto;
    }

    public async updateMachinePhoto(
        machineId: string,
        photo: File,
        onProgressPercentChange?: (percent: number) => void
    ) {
        if (!this.usersCompanyId) {
            return Promise.reject();
        }
        const photoPath = `companies/${this.usersCompanyId}/machines/${machineId}/photo.${getFileExtension(photo)}`;
        const uploadedPhoto = await this.uploadAppendix(photoPath, photo, onProgressPercentChange, {
            contentType: photo.type,
        });
        const updatedPhoto = {
            storagePath: photoPath,
            imageSrc: await getDownloadURL(uploadedPhoto.ref),
        };
        await this.updateMachine(machineId, {
            photo: updatedPhoto,
        });
        return updatedPhoto;
    }

    public async deleteMachinePhoto(machine: Machine) {
        if (machine && machine.photo) {
            await deleteObject(ref(this.storage(), machine.photo.storagePath));

            return this.updateMachine(machine.id, {
                photo: null,
            });
        }
    }

    public async deleteEmployeePhoto(employee: Employee) {
        if (employee && employee.photo) {
            await deleteObject(ref(this.storage(), employee.photo.storagePath));

            return this.updateEmployee(employee.id, {
                photo: null,
            });
        }
    }

    public async deleteCompanyLogo(operatingUnit: OperatingUnit) {
        if (operatingUnit && operatingUnit.logo) {
            await deleteObject(ref(this.storage(), operatingUnit.logo.storagePath));

            return this.updatePartialOperatingUnit(operatingUnit.id, {
                logo: null,
            });
        }
    }

    public getAppUser(userId?: string) {
        return doc(this.collections.appUsers(), userId);
    }

    public updateAppUser(appUserId: string, updatedValues: Partial<AppUser>) {
        return updateDoc(this.getAppUser(appUserId), updatedValues);
    }

    public getAllEmployees(includeArchived?: boolean) {
        const allEmployeesRef = this.collections.employees(this.usersCompanyId);
        return includeArchived ? allEmployeesRef : query(allEmployeesRef, where("archived", "==", false));
    }

    public getEmployeeRef(employeeId: string) {
        return doc(this.collections.employees(this.usersCompanyId), employeeId);
    }

    public insertEmployee(employeeToInsert: Employee) {
        const newEmployeeRef = doc(this.collections.employees(this.usersCompanyId));
        employeeToInsert = { ...employeeToInsert, id: newEmployeeRef.id };
        setDoc(newEmployeeRef, employeeToInsert);
        return employeeToInsert;
    }

    public updatePartialEmployee(id: string, updatedValues: Partial<Employee>): Promise<void>;
    public updatePartialEmployee(id: string, updatedValues: Partial<Employee>, batch: WriteBatch): WriteBatch;
    public updatePartialEmployee(id: string, updatedValues: Partial<Employee>, batch?: WriteBatch) {
        if (batch) {
            return batch.update(this.getEmployeeRef(id), updatedValues);
        } else {
            return updateDoc(this.getEmployeeRef(id), updatedValues);
        }
    }

    public updateWholeEmployee(employee: Employee) {
        return setDoc(this.getEmployeeRef(employee.id), employee);
    }

    public async deleteEmployee(employeeId: string) {
        await this.kickEmployee(employeeId);
        return updateDoc(this.getEmployeeRef(employeeId), { archived: true });
    }

    public getAllEmployeeLocations() {
        return this.collections.employeeLocations(this.usersCompanyId);
    }

    public setEmployeeLocation(newLocation: EmployeeLocation) {
        const currentAuthUserId = this.getCurrentAuthUserUid();
        if (currentAuthUserId) {
            return setDoc(doc(this.getAllEmployeeLocations(), currentAuthUserId), newLocation);
        }
    }

    public getAllFields(options?: { includeArchived?: boolean; companyId?: string }) {
        const allFieldsRef = this.collections.fields(options?.companyId ?? this.usersCompanyId);
        return options?.includeArchived ? allFieldsRef : query(allFieldsRef, where("archived", "==", false));
    }

    private getAllFieldsMeta(options?: { includeArchived?: boolean; companyId?: string }) {
        const allFieldsMetaRef = this.collections.fieldsMeta(options?.companyId ?? this.usersCompanyId);
        return options?.includeArchived ? allFieldsMetaRef : query(allFieldsMetaRef, where("archived", "==", false));
    }

    public getAllFieldsMetaForCustomer(parameters: {
        customerId: string;
        companyId?: AppCompany["id"];
        includeArchived?: boolean;
    }) {
        return query(
            this.getAllFieldsMeta({
                companyId: parameters.companyId ?? this.usersCompanyId,
                includeArchived: parameters.includeArchived,
            }),
            where("customerId", "==", parameters.customerId)
        );
    }

    public getAllFieldsMetaForCustomerIds(customerIds: string[], includeArchived = false) {
        return query(
            this.getAllFieldsMeta({
                companyId: this.usersCompanyId,
                includeArchived: includeArchived,
            }),
            where("customerId", "in", customerIds)
        );
    }

    public getAllTracksMetaForCustomerIds(customerIds: string[], includeArchived = false) {
        return query(
            this.getAllTracksMeta({
                companyId: this.usersCompanyId,
                includeArchived: includeArchived,
            }),
            where("customerId", "in", customerIds)
        );
    }

    public getFieldRef(fieldId: string, companyId: AppCompany["id"] | undefined) {
        return doc(this.collections.fields(companyId ?? this.usersCompanyId), fieldId);
    }

    public _getFieldMetaRef(fieldMetaId: string, companyId: AppCompany["id"] | undefined) {
        return doc(this.collections.fieldsMeta(companyId ?? this.usersCompanyId), fieldMetaId);
    }

    public insertField(field: Field, options?: { batch?: WriteBatch; companyId?: string }) {
        const newFieldRef = doc(this.collections.fields(options?.companyId ?? this.usersCompanyId));
        const newFieldMetaRef = doc(
            this.collections.fieldsMeta(options?.companyId ?? this.usersCompanyId),
            newFieldRef.id
        );
        const fieldToInsert = new Field({ ...field, id: newFieldRef.id });
        const newFieldMeta = FieldMeta.fromField(fieldToInsert);
        const batchToUse = options?.batch ?? this.createWriteBatch();
        batchToUse.set(newFieldRef, fieldToInsert);
        batchToUse.set(newFieldMetaRef, newFieldMeta);

        if (!options?.batch) {
            batchToUse.commit();
        }

        return newFieldRef.id;
    }

    public updatePartialField(parameters: {
        fieldId: string;
        updatedValues: Partial<Field>;
        batch?: WriteBatch;
        companyId: AppCompany["id"] | undefined;
    }) {
        const batchToUse = parameters.batch ?? this.createWriteBatch();
        const fieldRef = doc(this.collections.fields(parameters.companyId ?? this.usersCompanyId), parameters.fieldId);
        const fieldMetaRef = doc(
            this.collections.fieldsMeta(parameters.companyId ?? this.usersCompanyId),
            parameters.fieldId
        );
        batchToUse.update(fieldRef, parameters.updatedValues);
        batchToUse.update(fieldMetaRef, constructFieldMetaUpdateFromFieldUpdate(parameters.updatedValues));
        if (!parameters.batch) {
            return batchToUse.commit();
        }
    }

    public deleteField(fieldId: string, parameters: { companyId: AppCompany["id"] | undefined }): Promise<void>;
    public deleteField(
        fieldId: string,
        parameters: { batch: WriteBatch; companyId: AppCompany["id"] | undefined }
    ): void;
    public deleteField(fieldId: string, parameters: { batch?: WriteBatch; companyId: AppCompany["id"] | undefined }) {
        const updatedValues: Pick<Field, "archived"> = { archived: true };
        return this.updatePartialField({ fieldId, updatedValues, ...parameters });
    }

    public getAllLoadingOrUnloadingPoints(options?: { includeArchived?: boolean; companyId?: string }) {
        const allLoadingOrUnloadingPointsRef = this.collections.loadingOrUnloadingPoints(
            options?.companyId ?? this.usersCompanyId
        );
        return options?.includeArchived
            ? allLoadingOrUnloadingPointsRef
            : query(allLoadingOrUnloadingPointsRef, where("archived", "==", false));
    }

    public getAllCommonLoadingOrUnloadingPoints(includeArchived?: boolean) {
        return query(this.getAllLoadingOrUnloadingPoints({ includeArchived }), where("customerId", "==", null));
    }

    public getAllLoadingOrUnloadingPointsForCustomers(parameters: {
        customerIds: Customer["id"][];
        companyId: string | undefined;
        includeArchived?: boolean;
    }) {
        return query(
            this.getAllLoadingOrUnloadingPoints({
                includeArchived: parameters.includeArchived,
                companyId: parameters.companyId,
            }),
            where("customerId", "in", parameters.customerIds)
        );
    }

    public insertLoadingOrUnloadingPoint(loadingOrUnloadingPoint: LoadingOrUnloadingPoint, batch?: WriteBatch) {
        const newLoadingOrUnloadingPointRef = doc(this.collections.loadingOrUnloadingPoints(this.usersCompanyId));
        const insertedLoadingOrUnloadingPoint = { ...loadingOrUnloadingPoint, id: newLoadingOrUnloadingPointRef.id };

        if (batch) {
            batch.set(newLoadingOrUnloadingPointRef, insertedLoadingOrUnloadingPoint);
        } else {
            setDoc(newLoadingOrUnloadingPointRef, insertedLoadingOrUnloadingPoint);
        }
        return insertedLoadingOrUnloadingPoint;
    }

    public getLoadingOrUnloadingPointRef(
        loadingOrUnloadingPointId: LoadingOrUnloadingPoint["id"],
        companyId: AppCompany["id"] | undefined
    ) {
        return doc(
            this.collections.loadingOrUnloadingPoints(companyId ?? this.usersCompanyId),
            loadingOrUnloadingPointId
        );
    }

    public updateLoadingOrUnloadingPoint(parameters: {
        id: LoadingOrUnloadingPoint["id"];
        updateData: Partial<LoadingOrUnloadingPoint>;
        companyId: AppCompany["id"] | undefined;
    }): Promise<void>;
    public updateLoadingOrUnloadingPoint(parameters: {
        id: LoadingOrUnloadingPoint["id"];
        updateData: Partial<LoadingOrUnloadingPoint>;
        companyId: AppCompany["id"] | undefined;
        batch: WriteBatch;
    }): void;
    public updateLoadingOrUnloadingPoint(parameters: {
        id: LoadingOrUnloadingPoint["id"];
        updateData: Partial<LoadingOrUnloadingPoint>;
        companyId: AppCompany["id"] | undefined;
        batch?: WriteBatch;
    }) {
        const loadingOrUnloadingPointRef = this.getLoadingOrUnloadingPointRef(parameters.id, parameters.companyId);
        if (parameters.batch) {
            parameters.batch.update(loadingOrUnloadingPointRef, { ...parameters.updateData });
        } else {
            return updateDoc(loadingOrUnloadingPointRef, { ...parameters.updateData });
        }
    }

    public deleteLoadingOrUnloadingPoint(id: string, parameters: { companyId?: string }): Promise<void>;
    public deleteLoadingOrUnloadingPoint(id: string, parameters: { batch: WriteBatch; companyId?: string }): void;
    public deleteLoadingOrUnloadingPoint(id: string, parameters: { batch?: WriteBatch; companyId?: string }) {
        const loadingPointRef = doc(
            this.collections.loadingOrUnloadingPoints(parameters.companyId ?? this.usersCompanyId),
            id
        );
        const updateObject = { archived: true };
        if (parameters.batch) {
            parameters.batch.update(loadingPointRef, updateObject);
        } else {
            return updateDoc(loadingPointRef, updateObject);
        }
    }

    public getAllMachines(includeArchived?: boolean) {
        const allMachinesRef = this.collections.machines(this.usersCompanyId);
        return includeArchived ? allMachinesRef : query(allMachinesRef, where("archived", "==", false));
    }

    public getMachine(machineId: string) {
        return doc(this.collections.machines(this.usersCompanyId), machineId);
    }

    public copyMachine(machine: Machine) {
        return this.insertMachine({ ...machine, name: `Kopie von ${machine.name}`, id: "" });
    }

    public insertMachine(machine: Machine) {
        const newMachineRef = doc(this.collections.machines(this.usersCompanyId));
        machine = { ...machine, id: newMachineRef.id };
        setDoc(newMachineRef, machine);
        return machine;
    }

    public getLastMachineCounterTracking(machineId: string, counter: MachineCounterType) {
        return query(
            this.getAllMachineCounterTrackings(),
            where("source.machineId", "==", machineId),
            where("type", "==", counter),
            orderBy("endDate", "desc"),
            limit(1)
        );
    }

    public updateMachine(machineId: string, updatedValues: Partial<Machine>, batch?: WriteBatch) {
        const machineRef = doc(this.collections.machines(this.usersCompanyId), machineId);
        if (batch) {
            batch.update(machineRef, updatedValues);
        } else {
            return updateDoc(machineRef, updatedValues);
        }
    }

    public getMachineMaintenanceRecords(machineId: string) {
        return this.collections.machineMaintenanceRecords(machineId, this.usersCompanyId);
    }

    public getMachineMaintenanceRecordRef(machineId: string, maintenanceRecordId: string) {
        return doc(this.collections.machineMaintenanceRecords(machineId, this.usersCompanyId), maintenanceRecordId);
    }

    public insertMachineMaintenanceRecord(machineId: string, maintenanceRecord: MachineMaintenanceRecord) {
        const ref = doc(this.collections.machineMaintenanceRecords(machineId, this.usersCompanyId));
        maintenanceRecord.id = ref.id;

        setDoc(ref, {
            ...maintenanceRecord,
        });
    }

    public updateMachineMaintenanceRecord(
        machineId: string,
        machineMaintenanceRecordId: string,
        updateData: Partial<MachineMaintenanceRecord>
    ) {
        const ref = this.getMachineMaintenanceRecordRef(machineId, machineMaintenanceRecordId);
        updateDoc(ref, updateData);
    }

    public updateEmployee(employeeId: string, updatedValues: Partial<Employee>, batch?: WriteBatch) {
        const employeeRef = doc(this.collections.employees(this.usersCompanyId), employeeId);
        if (batch) {
            batch.update(employeeRef, updatedValues);
        } else {
            return updateDoc(employeeRef, updatedValues);
        }
    }

    public deleteMachine(machineId: string) {
        return updateDoc(doc(this.collections.machines(this.usersCompanyId), machineId), { archived: true });
    }

    public getAllMachineCounterTrackings(includeArchived?: boolean) {
        const allMachineCountersRef = this.collections.machineCounterTrackings(this.usersCompanyId);
        return includeArchived ? allMachineCountersRef : query(allMachineCountersRef, where("archived", "==", false));
    }

    public getAllMachineCounterTrackingsByMachine(machineId: string, includeArchived?: boolean) {
        return query(this.getAllMachineCounterTrackings(includeArchived), where("source.machineId", "==", machineId));
    }

    public getAllMachineCounterTrackingsByOrder(orderId: string, includeArchived?: boolean) {
        return query(this.getAllMachineCounterTrackings(includeArchived), where("orderId", "==", orderId));
    }

    public getAllMachineCounterTrackingsByRentalOrder(rentalOrderId: string, includeArchived?: boolean) {
        return query(this.getAllMachineCounterTrackings(includeArchived), where("rentalOrderId", "==", rentalOrderId));
    }

    public insertMachineCounterTrackings(trackings: MachineCounterTracking[], batch?: WriteBatch) {
        const prepareBatch = (batch: WriteBatch) => {
            for (const tracking of trackings) {
                const newMachineCounterTrackingRef = doc(this.collections.machineCounterTrackings(this.usersCompanyId));
                tracking.id = newMachineCounterTrackingRef.id;
                batch.set(newMachineCounterTrackingRef, tracking);
            }
        };

        if (batch) {
            return prepareBatch(batch);
        } else {
            const batch = this.createWriteBatch();
            prepareBatch(batch);
            return batch.commit();
        }
    }

    public deleteMachineCounterTrackings(trackingIds: string[], dontArchive?: boolean, batch?: WriteBatch) {
        const firestoreBatch = batch ?? this.createWriteBatch();
        for (const trackingId of trackingIds) {
            const machineCounterRef = doc(this.collections.machineCounterTrackings(this.usersCompanyId), trackingId);
            if (dontArchive) {
                firestoreBatch.delete(machineCounterRef);
            } else {
                firestoreBatch.update(machineCounterRef, { archived: true });
            }
        }

        if (batch) {
            return Promise.resolve();
        }
        return firestoreBatch.commit();
    }

    public updateMachineCounterTracking(trackingId: string, data: Partial<MachineCounterTracking>, batch?: WriteBatch) {
        const docRef = doc(this.collections.machineCounterTrackings(this.usersCompanyId), trackingId);

        if (batch) {
            return batch.update(docRef, { ...data });
        } else {
            return updateDoc(docRef, { ...data });
        }
    }

    public getAllGasStations(includeArchived?: boolean) {
        const allGasStationsRef = this.collections.gasStations(this.usersCompanyId);
        return includeArchived ? allGasStationsRef : query(allGasStationsRef, where("archived", "==", false));
    }

    public insertGasStation(gasStation: GasStation) {
        const newGasStationsRef = doc(this.collections.gasStations(this.usersCompanyId));
        const insertedGasStation = { ...gasStation, id: newGasStationsRef.id };
        setDoc(newGasStationsRef, insertedGasStation);
        return insertedGasStation;
    }

    private getGasStation(id: string) {
        return doc(this.collections.gasStations(this.usersCompanyId), id);
    }

    public updatePartialGasStation(id: string, valuesToUpdate: Partial<GasStation>, batch?: WriteBatch) {
        if (batch) {
            batch.update(this.getGasStation(id), valuesToUpdate);
            return;
        }
        return updateDoc(this.getGasStation(id), valuesToUpdate);
    }

    public updateWholeGasStation(gasStation: GasStation) {
        return setDoc(this.getGasStation(gasStation.id), gasStation);
    }

    public deleteGasStation(gasStationId: string) {
        return updateDoc(this.getGasStation(gasStationId), { archived: true });
    }

    public getAllRefuels(includeArchived?: boolean) {
        const allRefuelsRef = this.collections.refuels(this.usersCompanyId);
        return includeArchived ? allRefuelsRef : query(allRefuelsRef, where("archived", "==", false));
    }

    public createRefuel(refuel: Refuel, batch?: WriteBatch) {
        const newRefuelRef = doc(this.collections.refuels(this.usersCompanyId));
        refuel.id = newRefuelRef.id;
        if (batch) {
            batch.set(newRefuelRef, refuel);
            return;
        }
        return setDoc(newRefuelRef, refuel);
    }

    public getAllCustomers(includeArchived?: boolean) {
        const allCustomersRef = this.collections.customers(this.usersCompanyId);
        return includeArchived ? allCustomersRef : query(allCustomersRef, where("archived", "==", false));
    }

    public getAllCustomersForCompanyId(options: {
        includeArchived?: boolean;
        companyId: AppCompany["id"] | undefined;
    }) {
        const allCustomersRef = this.collections.customers(options.companyId ?? this.usersCompanyId);
        return options.includeArchived ? allCustomersRef : query(allCustomersRef, where("archived", "==", false));
    }

    public getCustomerRef(
        customerId: string,
        options?: {
            companyId?: string;
        }
    ) {
        return doc(this.collections.customers(options?.companyId ?? this.usersCompanyId), customerId);
    }

    public getCustomerWithHighestCustomerNumber() {
        return query(this.getAllCustomers(), orderBy("customerNumber", "desc"), limit(1));
    }

    public getCustomerWithSpecificCustomerNumber(customerNumber: number) {
        return query(this.getAllCustomers(), where("customerNumber", "==", customerNumber));
    }

    public insertCustomer(customer: Customer, unitsNextCustomerNumber: number, companyId: string) {
        const batch = this.createWriteBatch();

        const newCustomerRef = doc(this.collections.customers(this.usersCompanyId));
        customer.id = newCustomerRef.id;
        batch.set(newCustomerRef, customer);

        if (customer.customerNumber && customer.customerNumber === unitsNextCustomerNumber) {
            batch.update(this.getAppCompanyRef(companyId), {
                "counters.nextCustomerNumber": increment(1),
            } as unknown as Partial<AppCompany>);
        }
        batch.commit();

        return customer;
    }

    public updatePartialCustomer(
        customerId: Customer["id"],
        updates: UpdateData<Customer>,
        options?: { companyId?: string; nextCustomerNumber?: number; batch?: WriteBatch }
    ) {
        const batch = options?.batch ?? this.createWriteBatch();

        if (
            updates.customerNumber &&
            options?.nextCustomerNumber &&
            updates.customerNumber === options.nextCustomerNumber
        ) {
            // TODO: check in which unit the customer number needs to be increased
            batch.update(this.getAppCompanyRef(options?.companyId), {
                "counters.nextCustomerNumber": increment(1),
            });
        }

        batch.update(
            this.getCustomerRef(customerId, { companyId: options?.companyId }),
            preparePartialCustomerForFirestore(updates)
        );

        if (!options?.batch) {
            batch.commit();
        }
    }

    public async deleteCustomer(customerId: Customer["id"]) {
        if (!this.usersCompanyId) {
            recordError("Could not delete customer because company id was not set.", { customerId });
            return;
        }
        await this.kickCustomer(customerId);
        await this.deleteSharingTokens(this.usersCompanyId, { customerIds: [customerId] });
        return updateDoc(this.getCustomerRef(customerId), { archived: true });
    }

    public getAllMarkers(options: { archived?: boolean; companyId: AppCompany["id"] | undefined }) {
        const baseQuery = this.collections.markers(options.companyId ?? this.usersCompanyId);
        return options.archived ? baseQuery : query(baseQuery, where("archived", "==", false));
    }

    public getAllMarkersForCustomer(parameters: { customerId: string; companyId?: string; includeArchived?: boolean }) {
        const markersRef = this.collections.markers(parameters.companyId ?? this.usersCompanyId);
        const customerMarkersQuery = query(markersRef, where("customerId", "==", parameters.customerId));
        return parameters.includeArchived
            ? customerMarkersQuery
            : query(customerMarkersQuery, where("archived", "==", false));
    }

    public insertMarker(marker: Marker, options: { companyId: string | undefined; batch?: WriteBatch }) {
        if (marker.fieldId) {
            marker.type = MarkerType.FIELD;
            return this.insertFieldMarker({
                fieldId: marker.fieldId,
                marker,
                companyId: options.companyId ?? this.usersCompanyId,
                batch: options.batch,
            });
        } else if (marker.customerId) {
            marker.type = MarkerType.CUSTOMER;
            return this.insertCustomerMarker({
                customerId: marker.customerId,
                marker,
                companyId: options.companyId ?? this.usersCompanyId,
                batch: options.batch,
            });
        }
        return marker;
    }

    private insertCustomerMarker(parameters: {
        customerId: string;
        companyId: string | undefined;
        marker: Marker;
        batch?: WriteBatch;
    }) {
        const newMarkerRef = doc(this.collections.markers(parameters.companyId ?? this.usersCompanyId));
        const insertedMarker = { ...parameters.marker };
        insertedMarker.id = newMarkerRef.id;
        insertedMarker.type = MarkerType.CUSTOMER;
        insertedMarker.customerId = parameters.customerId;
        if (parameters.batch) {
            parameters.batch.set(newMarkerRef, { ...insertedMarker });
        } else {
            setDoc(newMarkerRef, { ...insertedMarker });
        }
        return insertedMarker;
    }

    private getMarker(markerId: string, companyId?: string) {
        return doc(this.collections.markers(companyId ?? this.usersCompanyId), markerId);
    }

    /**
     * Make sure to always await the result when passing a batch. This does not affect offline capability.
     * @param parameters
     */
    public updateMarker(parameters: {
        markerId: Marker["id"];
        updateData: Partial<Marker>;
        companyId: AppCompany["id"] | undefined;
        batch?: WriteBatch;
    }) {
        const markerUpdate = { ...parameters.updateData };
        if (markerUpdate.fieldId) {
            markerUpdate.type = MarkerType.FIELD;
            return this.updateFieldMarker({
                fieldId: markerUpdate.fieldId,
                markerId: parameters.markerId,
                updateData: markerUpdate,
                companyId: parameters.companyId,
                batch: parameters.batch,
            });
        } else if (markerUpdate.customerId) {
            markerUpdate.type = MarkerType.CUSTOMER;
            const markerRef = this.getMarker(parameters.markerId, parameters.companyId);
            if (parameters.batch) {
                parameters.batch.update(markerRef, markerUpdate);
            } else {
                return updateDoc(markerRef, markerUpdate);
            }
        }
    }

    public removeMarker(markerId: string, batch?: WriteBatch, companyId?: string) {
        const markerRef = this.getMarker(markerId, companyId);
        if (batch) {
            batch.update(markerRef, { archived: true });
            return;
        }
        return updateDoc(markerRef, {
            archived: true,
        });
    }

    private insertFieldMarker(parameters: {
        fieldId: string;
        marker: Marker;
        companyId: string | undefined;
        batch?: WriteBatch;
    }) {
        const marker = parameters.marker;
        marker.type = MarkerType.FIELD;
        this.updatePartialField({
            fieldId: parameters.fieldId,
            updatedValues: {
                markers: arrayUnion({ ...marker }) as unknown as Marker[],
            },
            companyId: parameters.companyId,
            batch: parameters.batch,
        });
        return marker;
    }

    /**
     * Make sure to always await the result when passing a batch. This does not affect offline capability.
     * However, if you pass a batch, the promise will only resolve once an answer from the backend arrives.
     * @param parameters
     */
    private async updateFieldMarker(parameters: {
        fieldId: Field["id"];
        markerId: Marker["id"];
        updateData: Partial<Marker>;
        companyId: AppCompany["id"] | undefined;
        batch?: WriteBatch;
    }) {
        const field = (await getDoc(this.getFieldRef(parameters.fieldId, parameters.companyId))).data();

        if (field) {
            const nextMarkers = (field.markers ?? []).map(marker => {
                if (marker.id === parameters.markerId) {
                    return {
                        ...marker,
                        ...parameters.updateData,
                    };
                }
                return { ...marker };
            });

            this.updatePartialField({
                fieldId: field.id,
                updatedValues: {
                    markers: nextMarkers,
                },
                companyId: parameters.companyId,
                batch: parameters.batch,
            });
        }
    }

    public async removeFieldMarker(parameters: {
        fieldId: string;
        markerId: string;
        batch?: WriteBatch;
        companyId?: string;
    }) {
        const field = (await getDoc(this.getFieldRef(parameters.fieldId, parameters.companyId))).data();

        if (field) {
            const nextMarkers = field.markers.filter(marker => marker.id !== parameters.markerId);
            this.updatePartialField({
                fieldId: field.id,
                updatedValues: {
                    markers: nextMarkers.map(marker => ({ ...marker })),
                },
                batch: parameters.batch,
                companyId: parameters.companyId,
            });
        }
    }

    public insertOrderMarker(orderId: string, marker: Marker) {
        this.updatePartialOrder(orderId, {
            markers: arrayUnion({ ...marker }) as unknown as Marker[],
        });
    }

    public getAllTracks(parameters?: { companyId?: string; includeArchived?: boolean }) {
        const tracksRef = this.collections.tracks(parameters?.companyId ?? this.usersCompanyId);
        return parameters?.includeArchived ? tracksRef : query(tracksRef, where("archived", "==", false));
    }

    private getAllTracksMeta(parameters?: { companyId?: string; includeArchived?: boolean }) {
        const tracksMetaRef = this.collections.tracksMeta(parameters?.companyId ?? this.usersCompanyId);
        return parameters?.includeArchived ? tracksMetaRef : query(tracksMetaRef, where("archived", "==", false));
    }

    public getAllTracksMetaForCustomer(parameters: {
        customerId: string;
        companyId?: string;
        includeArchived?: boolean;
    }) {
        return query(this.getAllTracksMeta(parameters), where("customerId", "==", parameters.customerId));
    }

    public insertTrack(track: Track, parameters?: { companyId?: string; batch?: WriteBatch }) {
        const batchToUse = parameters?.batch ?? this.createWriteBatch();

        const newTrackRef = doc(this.collections.tracks(parameters?.companyId ?? this.usersCompanyId));
        const insertedTrack = new Track({ ...track, id: newTrackRef.id });
        batchToUse.set(newTrackRef, insertedTrack);

        const newTrackMetaRef = doc(
            this.collections.tracksMeta(parameters?.companyId ?? this.usersCompanyId),
            newTrackRef.id
        );
        batchToUse.set(newTrackMetaRef, TrackMeta.fromTrack(insertedTrack));

        if (!parameters?.batch) {
            batchToUse.commit();
        }

        return new Track(insertedTrack);
    }

    public getTrackRef(trackId: string, companyId: AppCompany["id"] | undefined) {
        return doc(this.collections.tracks(companyId ?? this.usersCompanyId), trackId);
    }

    public getTrackMetaRef(trackId: string, companyId: AppCompany["id"] | undefined) {
        return doc(this.collections.tracksMeta(companyId ?? this.usersCompanyId), trackId);
    }

    public updateTrack(trackId: string, updateData: Partial<Track>, companyId: string | undefined, batch?: WriteBatch) {
        const batchToUse = batch ?? this.createWriteBatch();

        batchToUse.update(this.getTrackRef(trackId, companyId), updateData);
        batchToUse.update(
            doc(this.collections.tracksMeta(companyId ?? this.usersCompanyId), trackId),
            constructTrackMetaUpdateFromTrackUpdate(updateData)
        );

        if (!batch) {
            return batchToUse.commit();
        }
    }

    public deleteTrack(trackId: string, batch?: WriteBatch, companyId?: string) {
        const updateObject: Pick<Track, "archived"> = { archived: true };
        return this.updateTrack(trackId, updateObject, companyId, batch);
    }

    public getAllResources(includeArchived?: boolean) {
        const allResourcesRef = this.collections.resources(this.usersCompanyId);
        return includeArchived ? allResourcesRef : query(allResourcesRef, where("archived", "==", false));
    }

    public getResourceRef(resourceId: string) {
        return doc(this.collections.resources(this.usersCompanyId), resourceId);
    }

    public insertResource(resourceToSubmit: Resource, options?: { useId?: string }) {
        const newResourceRef = options?.useId
            ? doc(this.collections.resources(this.usersCompanyId), options.useId)
            : doc(this.collections.resources(this.usersCompanyId));
        const insertedResource = { ...resourceToSubmit, id: newResourceRef.id };
        setDoc(newResourceRef, insertedResource);
        return insertedResource;
    }

    private createResourceInventoryHistoryEntry = (
        resourceId: string,
        previousInventoryCount: number,
        newValue: number,
        authenticatedEmployee: Employee,
        batch: WriteBatch
    ) => {
        const authenticatedUserId = authenticatedEmployee.appUserId;
        if (authenticatedUserId) {
            const changeHistoryEntry = new ResourceInventoryHistoryEntry({
                version: dayjs().toISOString(),
                editor: {
                    appUserId: authenticatedUserId,
                    employeeId: authenticatedEmployee.id,
                    employeeName: `${authenticatedEmployee.firstName} ${authenticatedEmployee.lastName}`,
                },
                previousInventoryCount: previousInventoryCount,
                newValue: newValue,
            });

            const changeHistoryEntryRef = doc(
                this.collections.resourceInventoryChangeHistory(resourceId, this.usersCompanyId)
            );

            changeHistoryEntry.id = changeHistoryEntryRef.id;

            batch.set(changeHistoryEntryRef, changeHistoryEntry);
        }
    };

    public getResourceInventoryHistory(resourceId: string) {
        return this.collections.resourceInventoryChangeHistory(resourceId, this.usersCompanyId);
    }

    public async getAllResourceInventoryHistories(resources: Resource[], filter?: { from?: Date; to?: Date }) {
        const promises: Promise<ComplexResourceInventoryHistoryEntry[]>[] = [];

        const createQuery = (resource: Resource) => {
            if (!filter) {
                return this.getResourceInventoryHistory(resource.id);
            }

            const constraints: QueryConstraint[] = [];
            if (filter.from) {
                constraints.push(where("version", ">=", filter.from.toISOString()));
            }
            if (filter.to) {
                constraints.push(where("version", "<=", filter.to.toISOString()));
            }
            return query(this.getResourceInventoryHistory(resource.id), ...constraints);
        };

        for (const resource of resources) {
            promises.push(
                getDocs(createQuery(resource)).then(snapshot => {
                    return snapshot.docs.map(doc => {
                        return new ComplexResourceInventoryHistoryEntry({
                            ...doc.data(),
                            resourceName: resource.name,
                            resourceType: resource.unit,
                            resourceCategory: resource.category,
                        });
                    });
                })
            );
        }

        return await Promise.all(promises);
    }

    public updateResourceInventory(
        resourceId: Resource["id"],
        previousInventoryCount: number,
        changeObject: RelativeResourceInventoryChange | AbsoluteResourceInventoryChange,
        authenticatedEmployee: Employee
    ) {
        const batch = this.createWriteBatch();
        if (
            changeObject.mode === ResourceInventoryEditMode.ABSOLUTE &&
            changeObject.newAbsoluteInventory !== previousInventoryCount
        ) {
            const updatedValue: Partial<Resource> = { inventoryCount: changeObject.newAbsoluteInventory };
            batch.update(this.getResourceRef(resourceId), updatedValue);
            this.createResourceInventoryHistoryEntry(
                resourceId,
                previousInventoryCount,
                changeObject.newAbsoluteInventory,
                authenticatedEmployee,
                batch
            );
        } else if (changeObject.mode === ResourceInventoryEditMode.RELATIVE && changeObject.inventoryChange !== 0) {
            try {
                batch.update(this.getResourceRef(resourceId), {
                    inventoryCount: increment(changeObject.inventoryChange),
                });
            } catch {
                return;
            }
            this.createResourceInventoryHistoryEntry(
                resourceId,
                previousInventoryCount,
                previousInventoryCount + changeObject.inventoryChange,
                authenticatedEmployee,
                batch
            );
        }
        batch.commit();
    }

    public updateWholeResource(resource: Resource, previousInventoryCount?: number, authenticatedEmployee?: Employee) {
        const resourceRef = this.getResourceRef(resource.id);
        if (
            previousInventoryCount !== undefined &&
            authenticatedEmployee &&
            previousInventoryCount !== resource.inventoryCount
        ) {
            const batch = this.createWriteBatch();
            this.createResourceInventoryHistoryEntry(
                resource.id,
                previousInventoryCount,
                resource.inventoryCount,
                authenticatedEmployee,
                batch
            );
            batch.set(resourceRef, resource);
            return batch.commit();
        } else {
            return setDoc(resourceRef, resource);
        }
    }

    public updatePartialResource(resourceId: Resource["id"], updateData: Partial<Resource>, batch?: WriteBatch) {
        const ref = doc(this.collections.resources(this.usersCompanyId), resourceId);

        if (batch) {
            batch.update(ref, updateData);
            return;
        }

        return updateDoc(ref, updateData);
    }

    public deleteResource(resourceId: string) {
        return updateDoc(this.getResourceRef(resourceId), { archived: true });
    }

    public getAllOrders(includeArchived?: boolean) {
        const allOrdersRef = this.collections.orders(this.usersCompanyId);
        return includeArchived ? allOrdersRef : query(allOrdersRef, where("archived", "==", false));
    }

    public getAllOrdersWithStatuses(orderStatuses: OrderStatus[]) {
        return query(
            this.collections.orders(this.usersCompanyId),
            where("archived", "==", false),
            where("status", "in", orderStatuses)
        );
    }

    public getUnbilledOrders() {
        return query(this.getAllOrders(), where("status", "!=", OrderStatus.BILLED));
    }

    public getAllOrdersForEmployee(employeeId: string, daysIntoPast: number, includeArchived?: boolean) {
        return query(
            this.getAllOrders(includeArchived),
            where("employeeId", "==", employeeId),
            where("displayedEndDateTime", ">=", dayjs().subtract(daysIntoPast, "days").startOf("day").toISOString())
        );
    }

    public getOldestUnfinishedOrdersForUser(appUserId: string, maxResults: number) {
        return query(
            this.getAllOrders(),
            where("appUserId", "==", appUserId),
            where("status", "in", [OrderStatus.ASSIGNED, OrderStatus.IN_PROGRESS]),
            orderBy("displayedStartDateTime", "asc"),
            limit(maxResults)
        );
    }

    public getOrderRef(orderId: string) {
        return doc(this.collections.orders(this.usersCompanyId), orderId);
    }

    public getOrderWithHighestOrderNumber() {
        return query(this.getAllOrders(), orderBy("orderNumber", "desc"), limit(1));
    }

    public getAllBillableOrders(
        operatingUnitId: OperatingUnit["id"],
        options: { customerId: string; enableCheckedOrderStatus: boolean }
    ) {
        const constraints = [
            where("status", "==", options.enableCheckedOrderStatus ? OrderStatus.CHECKED : OrderStatus.DONE),
            where("operatingUnitId", "==", operatingUnitId),
            where(`bills.${options.customerId}`, "==", null),
            where("customerIds", "array-contains", options.customerId),
        ];

        return query(this.getAllOrders(), ...constraints);
    }

    public getAllBillableRentalOrders(
        operatingUnitId: OperatingUnit["id"],
        options: { customerId: string; enableCheckedOrderStatus: boolean }
    ) {
        const constraints = [
            where("status", "==", options.enableCheckedOrderStatus ? OrderStatus.CHECKED : OrderStatus.DONE),
            where("billId", "==", null),
            where("operatingUnitId", "==", operatingUnitId),
            where("customerId", "==", options.customerId),
        ];

        return query(this.getAllRentalOrders(), ...constraints);
    }

    public getCustomerOrdersStartingWithinInterval(
        customerIds: Customer["id"][],
        intervalStartIsoString: string,
        intervalEndIsoString: string
    ) {
        return query(
            this.getAllOrders(),
            where("customerIds", "array-contains-any", customerIds),
            where("displayedStartDateTime", ">", intervalStartIsoString),
            where("displayedStartDateTime", "<", intervalEndIsoString)
        );
    }

    public getCustomerOrdersEndingWithinInterval(
        customerIds: Customer["id"][],
        intervalStartIsoString: string,
        intervalEndIsoString: string
    ) {
        return query(
            this.getAllOrders(),
            where("customerIds", "array-contains-any", customerIds),
            where("displayedEndDateTime", ">", intervalStartIsoString),
            where("displayedEndDateTime", "<", intervalEndIsoString)
        );
    }

    public createOrderAndNotification(orderToSubmit: Order, batch?: WriteBatch): Order {
        const prepareBatch = (batch: WriteBatch) => {
            const newOrderRef = orderToSubmit.id
                ? doc(this.collections.orders(this.usersCompanyId), orderToSubmit.id)
                : doc(this.collections.orders(this.usersCompanyId));
            const insertedOrder: Order = {
                ...orderToSubmit,
                id: newOrderRef.id,
                plannedDates: computeDates(orderToSubmit.plannedStartDateTime, orderToSubmit.plannedEndDateTime),
                displayedDates: computeDates(orderToSubmit.displayedStartDateTime, orderToSubmit.displayedEndDateTime),
            };
            insertedOrder.notificationSeen = insertedOrder.appUserId === this.getCurrentAuthUserUid();
            batch.set(newOrderRef, insertedOrder);

            if (insertedOrder.appUserId) {
                this.createNotification(
                    new AlertNotification({
                        recipientAppUserId: insertedOrder.appUserId,
                        orderId: insertedOrder.id,
                        creationTime: dayjs().toISOString(),
                        updateTime: dayjs().toISOString(),
                        lastEditedBy: this.getCurrentAuthUserUid(),
                        notificationType: OrderNotificationType.NEW_ORDER,
                        actionButtonLabel: "Zum Auftrag",
                        headline: "Dir wurde ein neuer Auftrag zugewiesen",
                        dismissButtonLabel: "Später ansehen",
                        subHeadline: insertedOrder.name,
                        buttonLink: Routes.TASKS + "/" + insertedOrder.id,
                        seen: insertedOrder.appUserId === this.getCurrentAuthUserUid(),
                    }),
                    batch
                );
            }
            return insertedOrder;
        };

        if (batch) {
            return prepareBatch(batch);
        } else {
            const localBatch = this.createWriteBatch();
            const insertedOrder = prepareBatch(localBatch);
            localBatch.commit();
            return insertedOrder;
        }
    }

    public async getPhoto(photoPath: string, size: PhotoSize): Promise<IdentifiablePhoto> {
        try {
            const sizeAwarePath = getPhotoPath(photoPath, size);
            return {
                storagePath: photoPath,
                // TODO: check whether we can use getBlob instead of getDownloadURL
                imageSrc: await getDownloadURL(ref(this.storage(), sizeAwarePath)),
            };
        } catch (error) {
            // Fallback to loading the original image (e.g. if image was not resized yet or running in emulator)
            return {
                storagePath: photoPath,
                imageSrc: await getDownloadURL(ref(this.storage(), photoPath)),
            };
        }
    }

    public async uploadRentalOrderPhoto(
        orderId: string,
        photo: File,
        onProgressPercentChange?: (percent: number) => void
    ) {
        const photoPath = `companies/${this.usersCompanyId}/rentalOrders/${orderId}/photos/${photo.name}`;
        await this.uploadAppendix(photoPath, photo, onProgressPercentChange);
        return photoPath;
    }

    public async uploadOrderPhoto(orderId: string, photo: File, onProgressPercentChange?: (percent: number) => void) {
        const photoPath = `companies/${this.usersCompanyId}/orders/${orderId}/photos/${photo.name}`;
        await this.uploadAppendix(photoPath, photo, onProgressPercentChange);
        return photoPath;
    }

    public async deleteOrderPhoto(photoStoragePath: string) {
        const resizedFolderName = getResizedPhotosPath(photoStoragePath);
        const resizedFiles = await listAll(ref(this.storage(), resizedFolderName));

        const operations: Promise<void>[] = [deleteObject(ref(this.storage(), photoStoragePath))];

        for (const file of resizedFiles.items) {
            operations.push(deleteObject(ref(this.storage(), file.fullPath)));
        }

        return Promise.all(operations);
    }

    /**
     * Always await the result when passing a batch.
     * @param orderId
     * @param updatedValues
     * @param batch
     */
    public async updatePartialOrder(orderId: string, updatedValues: Partial<Order>, batch?: WriteBatch) {
        const prepareBatch = async (batch: WriteBatch) => {
            if (updatedValues.appUserId !== undefined || updatedValues.name !== undefined) {
                await this.updateNotificationsAfterOrderUpdate(
                    orderId,
                    batch,
                    updatedValues.appUserId,
                    updatedValues.name
                );
            }

            batch.update(this.getOrderRef(orderId), preparePartialOrderForFirestore(updatedValues));
        };

        if (batch) {
            await prepareBatch(batch);
            return;
        } else {
            const localBatch = this.createWriteBatch();
            await prepareBatch(localBatch);
            return localBatch.commit();
        }
    }

    public async updateWholeOrder(order: Order, batch?: WriteBatch) {
        const prepareBatch = async (batch: WriteBatch) => {
            batch.update(this.getOrderRef(order.id), preparePartialOrderForFirestore(order));

            await this.updateNotificationsAfterOrderUpdate(order.id, batch, order.appUserId, order.name);
        };
        if (batch) {
            await prepareBatch(batch);
            return;
        } else {
            const localBatch = this.createWriteBatch();
            await prepareBatch(localBatch);
            return localBatch.commit();
        }
    }

    getDueOrders() {
        return query(
            this.getAllOrders(),
            where("appUserId", "==", this.getCurrentAuthUserUid()),
            where("hasBeenStarted", "==", false),
            where("status", "==", OrderStatus.ASSIGNED),
            where("plannedStartDateTime", "<", dayjs().toISOString())
        );
    }

    /**
     * **Always await the result.** As the passed batch is used for write operations, this has no detrimental effect
     * on offline support.
     * @param orderId the id of thr order that the notifications are updated for
     * @param batch the batch to use for write operations
     * @param updatedAppUserId the uid of the user the order is assigned to
     * @param updatedOrderName the name of the order
     * @private
     */
    private async updateNotificationsAfterOrderUpdate(
        orderId: string,
        batch: WriteBatch,
        updatedAppUserId?: string | null,
        updatedOrderName?: string
    ) {
        const orderNotificationDocs = (
            await getDocs(
                query(
                    this.getAllNotifications(true),
                    where("orderId", "==", orderId),
                    where("notificationType", "==", OrderNotificationType.NEW_ORDER)
                )
            )
        ).docs;

        if (updatedAppUserId === null) {
            orderNotificationDocs.forEach(doc => batch.delete(doc.ref));
        } else if (updatedAppUserId) {
            if (orderNotificationDocs.length < 1) {
                // create notification
                this.createNotification(
                    new AlertNotification({
                        recipientAppUserId: updatedAppUserId,
                        orderId,
                        creationTime: dayjs().toISOString(),
                        updateTime: dayjs().toISOString(),
                        lastEditedBy: this.getCurrentAuthUserUid(),
                        notificationType: OrderNotificationType.NEW_ORDER,
                        actionButtonLabel: "Zum Auftrag",
                        headline: "Dir wurde ein neuer Auftrag zugewiesen",
                        dismissButtonLabel: "Später ansehen",
                        subHeadline: updatedOrderName ?? "Neuer Auftrag",
                        buttonLink: Routes.TASKS + "/" + orderId,
                        seen: updatedAppUserId === this.getCurrentAuthUserUid(),
                    }),
                    batch
                );
            } else {
                // update notification(s)
                orderNotificationDocs.forEach(doc => {
                    const updates: Partial<AlertNotification> = {
                        recipientAppUserId: updatedAppUserId,
                        lastEditedBy: this.getCurrentAuthUserUid(),
                    };
                    if (updatedAppUserId) {
                        updates.seen =
                            doc.data().seen &&
                            (updatedAppUserId === doc.data().recipientAppUserId ||
                                updatedAppUserId === this.getCurrentAuthUserUid());
                        updates.recipientAppUserId = updatedAppUserId;
                    }
                    if (updatedOrderName) {
                        updates.subHeadline = updatedOrderName;
                    }
                    batch.update(doc.ref, updates);
                });
            }
        }
    }

    /**
     * Start an order for an employee.
     * Make sure to **always await the result**. This has no impact on offline support
     */
    public async startOrderForEmployee(
        order: OrderWithoutOrderNumber,
        params: {
            activity: OrderWorkType;
            employee: Employee;
            customerId: Customer["id"];
            startTime?: string;
            createTrackingForPreviousActivity: boolean;
            mapStructure: TimeTrackingField | null;
        },
        batch: WriteBatch
    ) {
        const startActiveTimeUpdateData = startActiveTime(order, {
            customerId: params.customerId,
            startTime: params.startTime,
        });
        const updateObject: Partial<Order> = preparePartialOrderForFirestore({
            ...order,
            ...startActiveTimeUpdateData,
        });
        if (startActiveTimeUpdateData.activeTime) {
            const newActiveTime = startActiveTimeUpdateData.activeTime;
            const newActiveTimeId = newActiveTime[newActiveTime?.length - 1].id;
            const newTrackingRef = doc(this.collections.geolocationTrackings(order.id, this.usersCompanyId));
            batch.set(
                newTrackingRef,
                new GeolocationTracking({
                    id: newTrackingRef.id,
                    employeeId: params.employee.id,
                    trackingPlain: [],
                    runId: newActiveTimeId,
                    start: dayjs().unix(),
                })
            );
            updateObject.geoLocationTrackingIds = arrayUnion(newTrackingRef.id) as unknown as string[];
        }

        // Start new order
        batch.update(this.getOrderRef(order.id), updateObject);

        await this.startNewActivityForEmployee(
            {
                type: ActivityType.ORDER,
                startDateTime: params.startTime ?? dayjs().toISOString(),
                orderId: order.id,
                orderWorkType: params.activity,
                customerId: params.customerId,
                mapStructure: params.mapStructure,
            },
            params.employee,
            params.createTrackingForPreviousActivity ?? true,
            { batch }
        );
    }

    /**
     * Stop an order for an employee.
     * Make sure to **always await the result**. This has no impact on offline support
     */
    public async stopOrderOfEmployee(order: Order, employee: Employee, batch: WriteBatch) {
        await this.endCurrentActivityForEmployee(employee, { batch });

        batch.update(
            this.getOrderRef(order.id),
            preparePartialOrderForFirestore({
                ...endActiveTime(order),
                taskRecords: order.taskRecords,
                driverQueriesYesNo: order.driverQueriesYesNo,
                driverQueriesSingleValue: order.driverQueriesSingleValue,
                driverQueriesBeforeAfter: order.driverQueriesBeforeAfter,
                driverQueriesResourceWithAmount: order.driverQueriesResourceWithAmount,
                driverQueriesResourceOnly: order.driverQueriesResourceOnly,
                other: order.other,
            })
        );
    }

    public async deleteOrder(order: Order, dontArchive?: boolean) {
        const orderRef = this.getOrderRef(order.id);
        const batch = this.createWriteBatch();

        if (dontArchive) {
            batch.delete(orderRef);
        } else {
            batch.update(orderRef, { archived: true });
        }

        if (order.employeeId) {
            const employee = (await getDoc(this.getEmployeeRef(order.employeeId))).data();
            if (
                employee?.currentActivity?.type === ActivityType.ORDER &&
                employee?.currentActivity?.orderId === order.id
            ) {
                this.updatePartialEmployee(order.employeeId, { currentActivity: null }, batch);
            }
        }

        const notifications = await getDocs(
            query(
                this.getAllNotifications(true),
                where("orderId", "==", order.id),
                where("notificationType", "==", OrderNotificationType.NEW_ORDER)
            )
        );
        notifications.forEach(snapshot => this.deleteNotification(snapshot.data().id, batch));
        return batch.commit();
    }

    public addGeolocationTrackingData(orderId: string, geolocationTrackingId: string, data: VertexWithTime[]) {
        return updateDoc(
            doc(this.collections.geolocationTrackings(orderId, this.usersCompanyId), geolocationTrackingId),
            { trackingPlain: arrayUnion(...data) }
        );
    }

    public getAllGeoLocationTrackingsForOrder(orderId: string) {
        return this.collections.geolocationTrackings(orderId, this.usersCompanyId);
    }

    public getAllGeoLocationTrackingsForOrderRun(orderId: string, runId: ActiveTime["id"]) {
        return query(this.collections.geolocationTrackings(orderId, this.usersCompanyId), where("runId", "==", runId));
    }

    public getAllGeoLocationTrackingsForOrderInLast24Hours(orderId: string) {
        return query(
            this.collections.geolocationTrackings(orderId, this.usersCompanyId),
            where("start", ">", dayjs().subtract(24, "h").unix())
        );
    }

    public getGeoLocationTracking(orderId: string, id: string) {
        return doc(this.collections.geolocationTrackings(orderId, this.usersCompanyId), id);
    }

    public getDecoupledOrderTimeTrackingsRef(orderId: string) {
        return this.collections.decoupledOrderTimeTrackings(orderId, this.usersCompanyId);
    }

    public getDecoupledOrderTimeTrackingRef(orderId: string, timeTrackingId: TimeTracking["id"]) {
        return doc(this.collections.decoupledOrderTimeTrackings(orderId, this.usersCompanyId), timeTrackingId);
    }

    /**
     * We have to load all order time trackings. Make sure to always await the call.
     */
    public async deleteAllDecoupledOrderTimeTrackings(orderId: string, options?: { batch: WriteBatch }) {
        const decoupledTimeTrackingsSnapshot = await getDocs(this.getDecoupledOrderTimeTrackingsRef(orderId));

        const batch = options?.batch ?? this.createWriteBatch();
        for (const doc of decoupledTimeTrackingsSnapshot.docs) {
            batch.delete(doc.ref);
        }

        if (!options?.batch) {
            batch.commit();
        }
    }

    public createNewLiquidMixture(orderId: Order["id"], liquidMixture: LiquidMixture, options?: { batch: WriteBatch }) {
        if (!liquidMixture.id) {
            recordError("Tried creating liquid mixture without id.");
            return;
        }
        const newRef = doc(this.collections.liquidMixtures(orderId, this.usersCompanyId), liquidMixture.id);
        if (options?.batch) {
            options.batch.set(newRef, liquidMixture);
        } else {
            setDoc(newRef, liquidMixture);
        }
    }

    public deleteLiquidMixture(
        orderId: Order["id"],
        liquidMixtureId: LiquidMixture["id"],
        options?: {
            batch: WriteBatch;
        }
    ) {
        const ref = doc(this.collections.liquidMixtures(orderId, this.usersCompanyId), liquidMixtureId);
        if (options?.batch) {
            options.batch.delete(ref);
        } else {
            deleteDoc(ref);
        }
    }

    public getAllLiquidMixturesForOrder(orderId: Order["id"]) {
        return this.collections.liquidMixtures(orderId, this.usersCompanyId);
    }

    public getServiceTemplateDataRef() {
        return doc(this.collections.templateData(), "serviceTemplatesData");
    }

    public getAllServiceTemplates() {
        return this.collections.serviceTemplates();
    }

    public getAllServices(includeArchived?: boolean) {
        const allServicesRef = this.collections.services(this.usersCompanyId);
        return includeArchived ? allServicesRef : query(allServicesRef, where("archived", "==", false));
    }

    public getService(serviceId: string) {
        return doc(this.collections.services(this.usersCompanyId), serviceId);
    }

    public copyService(service: Service) {
        return this.insertService({ ...service, name: `Kopie von ${service.name}`, id: "" });
    }

    public insertService(service: Service): Service;
    public insertService(service: Service, batch: WriteBatch): WriteBatch;
    public insertService(service: Service, batch?: WriteBatch) {
        const newServiceRef = doc(this.collections.services(this.usersCompanyId));
        const insertedService = {
            ...service,
            id: newServiceRef.id,
        };
        if (batch) {
            return batch.set(newServiceRef, insertedService);
        } else {
            setDoc(newServiceRef, insertedService);
            return insertedService;
        }
    }

    public updateWholeService(service: Service): Promise<void>;
    public updateWholeService(service: Service, batch: WriteBatch): WriteBatch;
    public updateWholeService(service: Service, batch?: WriteBatch) {
        const serviceDocRef = this.getService(service.id);
        if (batch) {
            return batch.set(serviceDocRef, service);
        } else {
            return setDoc(serviceDocRef, service);
        }
    }

    public updatePartialService(serviceId: Service["id"], updateData: Partial<Service>, batch: WriteBatch) {
        const serviceRef = this.getService(serviceId);
        return batch.update(serviceRef, updateData);
    }

    public deleteService(serviceId: string) {
        return updateDoc(this.getService(serviceId), { archived: true });
    }

    public getAllRentalOrders(includeArchived?: boolean) {
        const allRentalOrdersRef = this.collections.rentalOrders(this.usersCompanyId);
        return includeArchived ? allRentalOrdersRef : query(allRentalOrdersRef, where("archived", "==", false));
    }

    public getAllRentalOrderAreaMeasurements(rentalOrderId: string) {
        const ref = this.collections.rentalOrderAreaMeasurements(rentalOrderId, this.usersCompanyId);
        return query(ref);
    }

    public createRentalOrderAreaMeasurement(rentalOrderId: string, measurement: AreaMeasurement) {
        const measurementDoc = doc(this.collections.rentalOrderAreaMeasurements(rentalOrderId, this.usersCompanyId));

        if (!measurement.id) {
            measurement.id = measurementDoc.id;
        }

        return setDoc(measurementDoc, { ...measurement });
    }

    public updateRentalOrderAreaMeasurement(rentalOrderId: string, measurement: AreaMeasurement) {
        if (!measurement.id) {
            return;
        }

        const measurementDoc = doc(
            this.collections.rentalOrderAreaMeasurements(rentalOrderId, this.usersCompanyId),
            measurement.id
        );

        return setDoc(measurementDoc, { ...measurement });
    }

    public deleteRentalOrderAreaMeasurement(rentalOrderId: string, measurementId: string) {
        const measurementDoc = doc(
            this.collections.rentalOrderAreaMeasurements(rentalOrderId, this.usersCompanyId),
            measurementId
        );

        return deleteDoc(measurementDoc);
    }

    public getAllRentalOrdersWithStatuses(statuses: RentalOrderStatus[]) {
        return query(
            this.collections.rentalOrders(this.usersCompanyId),
            where("archived", "==", false),
            where("status", "in", statuses)
        );
    }

    public getUnbilledRentalOrders() {
        return query(this.getAllRentalOrders(), where("status", "!=", OrderStatus.BILLED));
    }

    public getRentalOrderRef(rentalId: string) {
        return doc(this.collections.rentalOrders(this.usersCompanyId), rentalId);
    }

    /**
     * Create a rental order.
     * Make sure to **always await the result**. This has no impact on offline support
     */
    public async createRentalOrder(rentalOrder: RentalOrder) {
        const rentalOrderRef = rentalOrder.id
            ? doc(this.collections.rentalOrders(this.usersCompanyId), rentalOrder.id)
            : doc(this.collections.rentalOrders(this.usersCompanyId));
        rentalOrder = {
            ...rentalOrder,
            id: rentalOrderRef.id,
            machinePriceTrackings: await this.createMachinePriceTrackings(rentalOrder.machineIds),
        };
        setDoc(rentalOrderRef, rentalOrder);
        return rentalOrder;
    }

    public updatePartialRentalOrder(
        rentalOrderId: RentalOrder["id"],
        updateData: Partial<RentalOrder>,
        batch?: WriteBatch
    ) {
        const ref = this.getRentalOrderRef(rentalOrderId);

        if (batch) {
            batch.update(ref, updateData);
        } else {
            return updateDoc(ref, updateData);
        }
    }

    public updateWholeRentalOrder(rentalOrder: RentalOrder, batch?: WriteBatch) {
        const ref = this.getRentalOrderRef(rentalOrder.id);

        if (batch) {
            batch.set(ref, { ...rentalOrder });
        } else {
            return setDoc(ref, { ...rentalOrder });
        }
    }

    public deleteRentalOrder(rentalOrderId: string) {
        return updateDoc(this.getRentalOrderRef(rentalOrderId), {
            archived: true,
        });
    }

    public updatePlannedOrderDates(order: Order | RentalOrder, from: DateLike, to: DateLike) {
        const fromIso = dayjs(from).toISOString();
        const toIso = dayjs(to).toISOString();

        if (isRentalOrder(order)) {
            return this.updatePartialRentalOrder(order.id, {
                plannedStartDateTime: fromIso,
                plannedEndDateTime: toIso,
            });
        } else {
            return this.updatePartialOrder(order.id, {
                plannedStartDateTime: fromIso,
                plannedEndDateTime: toIso,
            });
        }
    }

    private async createMachinePriceTrackings(
        machineIds: Array<Machine["id"]>
    ): Promise<AnyRentalOrderPriceTracking[]> {
        const machines = await mergeQueryData(machineIds, ids => {
            return query(this.getAllMachines(), where("id", "in", ids));
        });

        return createRentalOrderPriceTrackings(machines);
    }

    public insertOvertime(overtime: Overtime) {
        const newOvertimeRef = doc(this.collections.overtimes(this.usersCompanyId));
        const newOvertime = new Overtime({
            ...overtime,
            id: newOvertimeRef.id,
        });
        return setDoc(newOvertimeRef, newOvertime);
    }

    public updateOverTime(id: Overtime["id"], overtimeUpdate: Partial<Overtime>) {
        return updateDoc(this.getOvertimeRef(id), { ...overtimeUpdate });
    }

    public deleteOvertime(id: Overtime["id"]) {
        return deleteDoc(this.getOvertimeRef(id));
    }

    public getAllOvertimes() {
        return this.collections.overtimes(this.usersCompanyId);
    }

    public getOvertimeForEmployeeInDateRange(employeeId: string, from: Date, until: Date) {
        return query(
            this.getAllOvertimes(),
            where("employeeId", "==", employeeId),
            where("date", ">=", from.toISOString()),
            where("date", "<=", until.toISOString()),
            orderBy("date")
        );
    }

    public getAllAbsences() {
        return this.collections.absences(this.usersCompanyId);
    }

    public getAbsenceRef(absenceId: Absence["id"]) {
        return doc(this.collections.absences(this.usersCompanyId), absenceId);
    }

    public upsertAbsence(absence: Absence) {
        if (absence.id) {
            return updateDoc(this.getAbsenceRef(absence.id), {
                ...absence,
                dates: computeDates(absence.startDate, absence.endDate),
            });
        }
        const absenceRef = doc(this.collections.absences(this.usersCompanyId));
        const absenceToCreate = new Absence({
            ...absence,
            id: absenceRef.id,
            dates: computeDates(absence.startDate, absence.endDate),
        });
        return setDoc(absenceRef, absenceToCreate);
    }

    public deleteAbsence(absenceId: Absence["id"]) {
        return deleteDoc(this.getAbsenceRef(absenceId));
    }

    public getAllTimeTrackings() {
        return this.collections.timeTrackings(this.usersCompanyId);
    }

    public getTimeTrackingRef(timeTrackingId: string) {
        return doc(this.getAllTimeTrackings(), timeTrackingId);
    }

    public getAllTimeTrackingsForOrder(orderLike: GetTimeTrackingsOrderLike) {
        if (orderLike.decoupleTimeTrackings) {
            return query(this.getDecoupledOrderTimeTrackingsRef(orderLike.id));
        }

        return query(this.getAllTimeTrackings(), where("order.orderId", "==", orderLike.id));
    }

    public getAllOwnTimeTrackingsForOrder(orderLike: GetTimeTrackingsOrderLike) {
        return query(
            this.getAllTimeTrackingsForOrder(orderLike),
            where("appUserId", "==", this.getCurrentAuthUserUid())
        );
    }

    public getOwnTimeTrackingsInDateRange(startDate: Dayjs, endDate: Dayjs) {
        const currentUserUid = this.getCurrentAuthUserUid();
        if (!currentUserUid) {
            return undefined;
        }
        return query(
            this.getAllTimeTrackings(),
            where("appUserId", "==", currentUserUid),
            where("startDateTime", ">=", startDate.toISOString()),
            where("startDateTime", "<=", endDate.toISOString()),
            orderBy("startDateTime")
        );
    }

    public getTimeTrackingsForEmployeeInDateRange(employeeId: string, startDate: Dayjs, endDate: Dayjs) {
        return query(
            this.getAllTimeTrackings(),
            where("employeeId", "==", employeeId),
            where("startDateTime", ">=", startDate.toISOString()),
            where("startDateTime", "<=", endDate.toISOString()),
            orderBy("startDateTime")
        );
    }

    public async getTimeTrackingsForEmployeesInDateRange(employeeIds: string[], startDate: Dayjs, endDate: Dayjs) {
        startDate = startDate.startOf("day");
        endDate = endDate.endOf("day");
        const timeTrackingsAfterEndDateDocs = (
            await getDocs(
                query(
                    this.getAllTimeTrackings(),
                    where("employeeId", "in", employeeIds),
                    where("startDateTime", ">", endDate.endOf("day").toISOString()),
                    orderBy("startDateTime"),
                    limit(1)
                )
            )
        ).docs;
        const firstTimeTrackingToExcludeDoc = timeTrackingsAfterEndDateDocs
            ? timeTrackingsAfterEndDateDocs[0]
            : undefined;

        const queryForRelevantTimeTrackings = query(
            this.getAllTimeTrackings(),
            where("employeeId", "in", employeeIds),
            where("startDateTime", ">=", startDate.toISOString()),
            orderBy("startDateTime")
        );

        return firstTimeTrackingToExcludeDoc
            ? query(queryForRelevantTimeTrackings, endBefore(firstTimeTrackingToExcludeDoc))
            : queryForRelevantTimeTrackings;
    }

    public async updateWholeTimeTracking(
        timeTracking: TimeTracking,
        forOrderWithDecoupledTimeTrackings: Order["id"] | null,
        batch?: WriteBatch
    ) {
        const updateBatch = batch ?? this.createWriteBatch();

        if (dayjs(timeTracking.startDateTime).isSame(dayjs(timeTracking.endDateTime), "dates")) {
            const ref = forOrderWithDecoupledTimeTrackings
                ? this.getDecoupledOrderTimeTrackingRef(forOrderWithDecoupledTimeTrackings, timeTracking.id)
                : this.getTimeTrackingRef(timeTracking.id);
            // explicitly use constructor, so startDate and endDate are set for querying
            updateBatch.update(ref, {
                ...new TimeTracking({
                    ...timeTracking,
                    name: await generateTimeTrackingName(timeTracking, this),
                }),
            });
        } else {
            const oldRef = forOrderWithDecoupledTimeTrackings
                ? this.getDecoupledOrderTimeTrackingRef(forOrderWithDecoupledTimeTrackings, timeTracking.id)
                : this.getTimeTrackingRef(timeTracking.id);
            updateBatch.delete(oldRef);
            this.deleteTimeTracking(timeTracking.id, forOrderWithDecoupledTimeTrackings, updateBatch);
            await this.createTimeTracking({ ...timeTracking, id: "" }, forOrderWithDecoupledTimeTrackings, updateBatch);
        }
        if (batch) {
            return;
        }
        return updateBatch.commit();
    }

    /**
     * Create a time tracking.
     * Make sure to **always await the result**. This has no impact on offline support
     */
    public async createTimeTracking(
        timeTracking: TimeTracking,
        forOrderWithDecoupledTimeTrackings: Order["id"] | null,
        batch?: WriteBatch
    ) {
        if (dayjs(timeTracking.endDateTime).isBefore(dayjs(timeTracking.startDateTime))) {
            throw new Error("invalid-datetimes");
        } else {
            const timeTrackingsToCreate = await this.generateTimeTrackings(timeTracking);

            const batchToUse = batch ?? this.createWriteBatch();

            timeTrackingsToCreate.forEach(tt => {
                const timeTrackingRef = doc(
                    forOrderWithDecoupledTimeTrackings
                        ? this.getDecoupledOrderTimeTrackingsRef(forOrderWithDecoupledTimeTrackings)
                        : this.getAllTimeTrackings()
                );
                const timeTrackingToSubmit = new TimeTracking({
                    ...tt,
                    id: timeTrackingRef.id,
                    appUserId: tt.appUserId || this.getCurrentAuthUserUid(),
                });
                batchToUse.set(timeTrackingRef, timeTrackingToSubmit);
            });

            if (!batch) {
                batchToUse.commit();
            }
        }
    }

    /**
     * Generate daily time trackings for potentially multi day time tracking.
     * Make sure to **always await the result**. This has no impact on offline support
     */
    private generateTimeTrackings = async (timeTracking: TimeTracking) => {
        const timeTrackingsToCreate: TimeTracking[] = [];
        let currentDay = dayjs(timeTracking.startDateTime);
        const end = dayjs(timeTracking.endDateTime);
        const timeTrackingName = await generateTimeTrackingName(timeTracking, this);
        while (!currentDay.isAfter(end)) {
            timeTrackingsToCreate.push(
                new TimeTracking({
                    ...timeTracking,
                    name: timeTrackingName,
                    startDateTime: currentDay.toISOString(),
                    endDateTime: currentDay.endOf("day").isBefore(end)
                        ? currentDay.endOf("day").toISOString()
                        : end.toISOString(),
                })
            );
            currentDay = currentDay.add(1, "day").startOf("day");
        }
        return timeTrackingsToCreate;
    };

    public deleteTimeTracking(
        timeTrackingId: string,
        forOrderWithDecoupledTimeTrackings: Order["id"] | null,
        batch?: WriteBatch
    ) {
        const timeTrackingRef = forOrderWithDecoupledTimeTrackings
            ? this.getDecoupledOrderTimeTrackingRef(forOrderWithDecoupledTimeTrackings, timeTrackingId)
            : this.getTimeTrackingRef(timeTrackingId);

        if (batch) {
            return batch.delete(timeTrackingRef);
        } else {
            return deleteDoc(timeTrackingRef);
        }
    }

    public createNotification(notification: AlertNotification, batch?: WriteBatch) {
        const newNotificationRef = doc(this.collections.notifications(this.usersCompanyId));
        const newNotification = { ...notification, id: newNotificationRef.id };
        if (batch) {
            return batch.set(newNotificationRef, newNotification);
        }
        return setDoc(newNotificationRef, newNotification);
    }

    public getAllNotifications(includeSeen?: boolean) {
        const notificationsRef = this.collections.notifications(this.usersCompanyId);
        return includeSeen ? notificationsRef : query(notificationsRef, where("seen", "==", false));
    }

    public getAllNewOrderNotificationsForUser(userId: string, includeSeen?: boolean) {
        return query(
            this.getAllNotifications(includeSeen),
            where("recipientAppUserId", "==", userId),
            where("lastEditedBy", "!=", userId)
        );
    }

    private getNotificationRef(notificationId: string) {
        return doc(this.collections.notifications(this.usersCompanyId), notificationId);
    }

    public async updateNotification(id: string, notificationUpdates: Partial<AlertNotification>, batch?: WriteBatch) {
        const notificationRef = this.getNotificationRef(id);
        if (batch) {
            return batch.update(notificationRef, notificationUpdates);
        }
        return updateDoc(notificationRef, notificationUpdates);
    }

    public deleteNotification(notificationId: string, batch?: WriteBatch) {
        const notificationRef = this.getNotificationRef(notificationId);
        if (batch) {
            return batch.delete(notificationRef);
        }
        return deleteDoc(notificationRef);
    }

    /**
     * Stop the current activity of an employee.
     * Make sure to **always await the result**. This has no impact on offline support
     */
    public endCurrentActivityForEmployee(
        employee: Employee,
        options?:
            | {
                  batch: WriteBatch;
                  endTimeOfPreviousActivity?: string;
              }
            | {
                  batch?: undefined;
                  endTimeOfPreviousActivity?: string;
                  waitForServerResponse?: boolean;
              }
    ) {
        return this.updateCurrentActivity(null, employee, true, options);
    }

    /**
     * Update the current activity of an employee.
     * Make sure to **always await the result**. This has no impact on offline support
     */
    public startNewActivityForEmployee(
        newActivity: Activity,
        employee: Employee,
        createTimeTrackingForPreviousActivity: boolean,
        options?:
            | {
                  batch: WriteBatch;
              }
            | {
                  batch?: undefined;
                  waitForServerResponse?: boolean;
              }
    ) {
        return this.updateCurrentActivity(newActivity, employee, createTimeTrackingForPreviousActivity, options);
    }

    /**
     * Update the next activity for an employee. This will stop the current activity if any.
     * Make sure to **always await the result**. This has no impact on offline support
     */
    private async updateCurrentActivity(
        newActivity: Activity | null,
        employee: Employee,
        createTimeTrackingForPreviousActivity: boolean,
        options?:
            | {
                  batch: WriteBatch;
                  endTimeOfPreviousActivity?: string;
              }
            | {
                  batch?: undefined;
                  endTimeOfPreviousActivity?: string;
                  waitForServerResponse?: boolean;
              }
    ) {
        const batchToUse = options?.batch ?? this.createWriteBatch();

        if (createTimeTrackingForPreviousActivity && employee.currentActivity?.startDateTime && employee.appUserId) {
            const timeTracking = ((): TimeTracking => {
                if (employee.currentActivity.type === ActivityType.INTERNAL) {
                    return new TimeTracking({
                        startDateTime: dayjs(employee.currentActivity.startDateTime).toISOString(),
                        endDateTime: options?.endTimeOfPreviousActivity ?? dayjs().toISOString(),
                        internalWorkType: employee.currentActivity.internalWorkType,
                        orderWorkType: null,
                        order: null,
                        appUserId: employee.appUserId,
                        employeeId: employee.id,
                        note: employee.currentActivity.note,
                    });
                }

                return new TimeTracking({
                    startDateTime: dayjs(employee.currentActivity.startDateTime).toISOString(),
                    endDateTime: dayjs().toISOString(),
                    internalWorkType: null,
                    orderWorkType: employee.currentActivity.orderWorkType,
                    order: {
                        orderId: employee.currentActivity.orderId,
                        customerId: employee.currentActivity.customerId,
                        mapStructure: employee.currentActivity.mapStructure,
                    },
                    appUserId: employee.appUserId,
                    employeeId: employee.id,
                    note: employee.currentActivity.note,
                });
            })();

            await this.createTimeTracking(timeTracking, null);
        }

        batchToUse.update(this.getEmployeeRef(employee.id), { currentActivity: newActivity });

        if (!options?.batch) {
            if (options?.waitForServerResponse) {
                await batchToUse.commit();
            } else {
                batchToUse.commit();
            }
        }
    }

    public getAllRefuelsForOrder(orderId: Order["id"]) {
        return query(this.getAllRefuels(), where("orderId", "==", orderId));
    }

    public getAllRefuelsForMachine(machineId: Machine["id"], includeArchived?: boolean) {
        return query(this.getAllRefuels(includeArchived), where("machineId", "==", machineId));
    }

    public getAllInvitationsForInvitedAppUserId(invitedAppUserId: string) {
        return query(this.collections.invitations(), where("invitedAppUserId", "==", invitedAppUserId));
    }

    public getRecentlyPaidBills(paidBeforeMaxDays: number) {
        return query(
            this.getAllBills(),
            where("paymentDate", ">=", dayjs().subtract(paidBeforeMaxDays, "days").format("YYYY-MM-DD"))
        );
    }

    public getAllUnpaidBills() {
        return query(this.getAllBills(), where("paymentDate", "==", null));
    }

    public getArchivedBillsInDateRange(startDate: Dayjs, endDate: Dayjs) {
        return query(
            this.getAllBills(),
            where("paymentDate", ">=", startDate.format("YYYY-MM-DD")),
            where("paymentDate", "<=", endDate.format("YYYY-MM-DD")),
            orderBy("paymentDate")
        );
    }

    public getBillRef(billId: string) {
        return doc(this.collections.bills(this.usersCompanyId), billId);
    }

    public getOvertimeRef(overtimeId: Overtime["id"]) {
        return doc(this.collections.overtimes(this.usersCompanyId), overtimeId);
    }

    public getAllBills() {
        return query(this.collections.bills(this.usersCompanyId), where("archived", "==", false));
    }

    public getAllBillsInDataRangeForOperatingUnit(
        startDate: Dayjs,
        endDate: Dayjs,
        operatingUnitId: OperatingUnit["id"]
    ) {
        return query(
            this.getAllBills(),
            where("date", ">=", startDate.format("YYYY-MM-DD")),
            where("date", "<=", endDate.format("YYYY-MM-DD")),
            where("operatingUnitId", "==", operatingUnitId),
            orderBy("date")
        );
    }

    public getBillWithHighestReceiptNumber(operatingUnitId: OperatingUnit["id"]) {
        return query(
            this.getAllBills(),
            where("operatingUnitId", "==", operatingUnitId),
            orderBy("receiptNumber", "desc"),
            limit(1)
        );
    }

    public getBillsWithReceiptNumber(receiptNumber: string, operatingUnitId: OperatingUnit["id"]) {
        return query(
            this.getAllBills(),
            where("receiptNumber", "==", receiptNumber),
            where("operatingUnitId", "==", operatingUnitId)
        );
    }

    public async updatePartialBill(
        billId: string,
        updatedValues: Partial<Bill>,
        authenticatedEmployee: Employee
    ): Promise<void>;
    public updatePartialBill(
        billId: string,
        updatedValues: Partial<Bill>,
        authenticatedEmployee: Employee,
        transaction: Transaction
    ): void;
    public async updatePartialBill(
        billId: string,
        updatedValues: Partial<Bill>,
        authenticatedEmployee: Employee,
        transaction?: Transaction
    ) {
        const billRef = this.getBillRef(billId);
        if (transaction) {
            const billDoc = await transaction.get(billRef);
            const updatedBill = { ...billDoc.data(), ...updatedValues } as Bill;
            transaction.update(billRef, updatedValues);
            if (updatedBill) {
                this.createBillHistoryEntry(updatedBill, BillChangeCategory.UPDATED, authenticatedEmployee, {
                    transaction: transaction,
                });
            }
        } else {
            updateDoc(billRef, {
                ...updatedValues,
                ...(updatedValues.paymentInfos ? { paymentInfos: updatedValues.paymentInfos } : undefined),
            });
            const bill = (await getDoc(billRef)).data();
            if (bill) {
                return this.createBillHistoryEntry(bill, BillChangeCategory.UPDATED, authenticatedEmployee, {
                    transaction: transaction,
                });
            }
        }
    }

    public updateWholeBill(bill: Bill, authenticatedEmployee: Employee) {
        const copiedBill = rfdc()(bill);
        // datev transfer is populated by backend
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { datevTransfer, ...billWithoutDatevTransfer } = copiedBill;
        const billRef = this.getBillRef(bill.id);
        const batch = this.createWriteBatch();
        batch.update(billRef, billWithoutDatevTransfer);
        this.createBillHistoryEntry(copiedBill, BillChangeCategory.UPDATED, authenticatedEmployee, { batch });
        return batch.commit();
    }

    public createBill(bill: Bill, authenticatedEmployee: Employee, batch?: WriteBatch) {
        const newBillRef = doc(this.collections.bills(this.usersCompanyId));
        const billToInsert: Bill = {
            ...bill,
            id: newBillRef.id,
            datevTransfer: null,
        };
        if (batch) {
            batch.set(newBillRef, billToInsert);
            this.createBillHistoryEntry(billToInsert, BillChangeCategory.CREATED, authenticatedEmployee, { batch });
        } else {
            const batch = this.createWriteBatch();

            batch.set(newBillRef, billToInsert);
            this.createBillHistoryEntry(billToInsert, BillChangeCategory.CREATED, authenticatedEmployee, { batch });

            batch.commit();
        }
        return billToInsert;
    }

    public getBillHistory(billId: string) {
        return this.collections.billChangeHistory(billId, this.usersCompanyId);
    }

    public createBillHistoryEntry(
        snapshot: Bill,
        reason: BillChangeCategory,
        authenticatedEmployee: Employee
    ): Promise<void>;
    public createBillHistoryEntry(
        snapshot: Bill,
        reason: BillChangeCategory,
        authenticatedEmployee: Employee,
        write: { batch?: WriteBatch; transaction?: Transaction }
    ): void;
    public createBillHistoryEntry(
        snapshot: Bill,
        reason: BillChangeCategory,
        authenticatedEmployee: Employee,
        write?: { batch?: WriteBatch; transaction?: Transaction }
    ) {
        const authenticatedUserId = authenticatedEmployee.appUserId;
        if (authenticatedUserId) {
            const changeHistoryEntry = new BillChangeHistoryEntry({
                version: dayjs().toISOString(),
                category: reason,
                editor: {
                    appUserId: authenticatedUserId,
                    employeeId: authenticatedEmployee.id,
                    employeeName: `${authenticatedEmployee.firstName} ${authenticatedEmployee.lastName}`,
                },
                snapshot: { ...snapshot },
            });
            const changeHistoryEntryRef = doc(this.collections.billChangeHistory(snapshot.id, this.usersCompanyId));

            changeHistoryEntry.id = changeHistoryEntryRef.id;
            if (write?.batch) {
                write.batch.set(changeHistoryEntryRef, changeHistoryEntry);
            } else if (write?.transaction) {
                write.transaction.set(changeHistoryEntryRef, changeHistoryEntry);
            } else {
                return setDoc(changeHistoryEntryRef, changeHistoryEntry);
            }
        }
    }

    public deleteBill(bill: Bill, authenticatedEmployee: Employee) {
        const billRef = this.getBillRef(bill.id);
        const batch = this.createWriteBatch();
        batch.update(billRef, { archived: true });
        this.createBillHistoryEntry(
            {
                ...bill,
                archived: true,
            },
            BillChangeCategory.DELETED,
            authenticatedEmployee,
            { batch }
        );
        return batch.commit();
    }

    public getAllBillRemindersWithinDateRange(fromDate: string, untilDate: string) {
        return query(
            this.collections.billReminders(this.usersCompanyId),
            where("date", ">", dayjs(fromDate).subtract(1, "day").endOf("day").toISOString()),
            where("date", "<", dayjs(untilDate).add(1, "day").startOf("day").toISOString())
        );
    }

    public getAllBillRemindersForBill(billId: string) {
        return query(this.collections.billReminders(this.usersCompanyId), where("billId", "==", billId));
    }

    public getBillReminderRef(billReminderId: string) {
        return doc(this.collections.billReminders(this.usersCompanyId), billReminderId);
    }

    public createBillReminder(billReminder: BillReminder) {
        const billReminderRef = doc(this.collections.billReminders(this.usersCompanyId));
        const billReminderToInsert = { ...billReminder, id: billReminderRef.id };
        setDoc(billReminderRef, billReminderToInsert);
        return billReminderToInsert;
    }

    public updateWholeBillReminder(billReminder: BillReminder) {
        return setDoc(this.getBillReminderRef(billReminder.id), billReminder);
    }

    public deleteBillReminder(billReminderId: string) {
        return deleteDoc(this.getBillReminderRef(billReminderId));
    }

    public getDeliveryNoteRef(noteId: string) {
        return doc(this.collections.deliveryNotes(this.usersCompanyId), noteId);
    }

    public getAllDeliveryNotes() {
        return query(this.collections.deliveryNotes(this.usersCompanyId), where("archived", "==", false));
    }

    public getAllDoneOrdersWithoutDeliveryNote(
        operatingUnitId: OperatingUnit["id"],
        options: { customerId: Customer["id"]; enableCheckedOrderStatus: boolean }
    ) {
        const constraints = [
            where("status", "in", [OrderStatus.DONE, OrderStatus.CHECKED, OrderStatus.BILLED]),
            where("operatingUnitId", "==", operatingUnitId),
            where(`deliveryNotes.${options.customerId}`, "==", null),
            where("customerIds", "array-contains", options.customerId),
        ];

        return query(this.getAllOrders(), ...constraints);
    }

    public getAllDoneRentalOrdersWithoutDeliveryNote(
        operatingUnitId: OperatingUnit["id"],
        options: { customerId: Customer["id"]; enableCheckedOrderStatus: boolean }
    ) {
        const constraints = [
            where("status", "in", [OrderStatus.DONE, OrderStatus.CHECKED, OrderStatus.BILLED]),
            where("operatingUnitId", "==", operatingUnitId),
            where("deliveryNoteId", "==", null),
            where("customerId", "==", options.customerId),
        ];

        return query(this.getAllRentalOrders(), ...constraints);
    }

    public getDeliveryNoteWithHighestReceiptNumber(operatingUnitId: OperatingUnit["id"]) {
        return query(
            this.getAllDeliveryNotes(),
            where("operatingUnitId", "==", operatingUnitId),
            orderBy("receiptNumber", "desc"),
            limit(1)
        );
    }

    public getDeliveryNotesWithReceiptNumber(receiptNumber: string, operatingUnitId: OperatingUnit["id"]) {
        return query(
            this.getAllDeliveryNotes(),
            where("receiptNumber", "==", receiptNumber),
            where("operatingUnitId", "==", operatingUnitId)
        );
    }

    public getAllOpenDeliveryNotes() {
        return query(this.getAllDeliveryNotes(), where("deliveryNoteStatus", "==", DeliveryNoteStatus.OPEN));
    }

    public getAllApprovedDeliveryNotesInDateRange(startDate: Dayjs, endDate: Dayjs) {
        return query(
            this.getAllDeliveryNotes(),
            where("deliveryNoteStatus", "==", DeliveryNoteStatus.APPROVED),
            where("date", ">=", startDate.format("YYYY-MM-DD")),
            where("date", "<=", endDate.format("YYYY-MM-DD")),
            orderBy("date")
        );
    }

    public createDeliveryNote(note: DeliveryNote, batch?: WriteBatch): DeliveryNote {
        const deliveryNoteRef = doc(this.collections.deliveryNotes(this.usersCompanyId));
        const deliveryNoteToInsert: DeliveryNote = {
            ...note,
            id: deliveryNoteRef.id,
            authorId: this.getCurrentAuthUserUid() ?? "",
        };

        if (batch) {
            batch.set(deliveryNoteRef, deliveryNoteToInsert);
        } else {
            setDoc(deliveryNoteRef, deliveryNoteToInsert);
        }

        return deliveryNoteToInsert;
    }

    public updateWholeDeliveryNote(note: DeliveryNote): Promise<void>;
    public updateWholeDeliveryNote(note: DeliveryNote, batch: WriteBatch): WriteBatch;
    public updateWholeDeliveryNote(note: DeliveryNote, batch?: WriteBatch) {
        const deliveryNoteDocRef = this.getDeliveryNoteRef(note.id);
        if (batch) {
            return batch.set(deliveryNoteDocRef, note);
        }
        return setDoc(deliveryNoteDocRef, note);
    }

    public updatePartialDeliveryNote(noteId: string, updatedValues: Partial<DeliveryNote>, transaction?: Transaction) {
        const deliveryNoteRef = this.getDeliveryNoteRef(noteId);
        if (transaction) {
            return transaction.update(deliveryNoteRef, updatedValues);
        } else {
            return updateDoc(deliveryNoteRef, {
                ...updatedValues,
            });
        }
    }

    public deleteDeliveryNote(deliveryNoteId: string) {
        return updateDoc(this.getDeliveryNoteRef(deliveryNoteId), { archived: true });
    }

    public getCreditNoteRef(creditNoteId: string) {
        return doc(this.collections.creditNotes(this.usersCompanyId), creditNoteId);
    }

    public getAllCreditNotes() {
        return query(this.collections.creditNotes(this.usersCompanyId), where("archived", "==", false));
    }

    public getAllCreditNotesInDataRangeForOperatingUnit(
        startDate: Dayjs,
        endDate: Dayjs,
        operatingUnitId: OperatingUnit["id"]
    ) {
        return query(
            this.getAllCreditNotes(),
            where("date", ">=", startDate.format("YYYY-MM-DD")),
            where("date", "<=", endDate.format("YYYY-MM-DD")),
            where("operatingUnitId", "==", operatingUnitId),
            orderBy("date")
        );
    }

    public updateWholeCreditNote(creditNote: CreditNote) {
        // datev transfer is populated by backend
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { datevTransfer, ...creditNoteWithoutDatevTransfer } = rfdc()(creditNote);
        const creditNoteRef = this.getCreditNoteRef(creditNote.id);
        return updateDoc(creditNoteRef, creditNoteWithoutDatevTransfer);
    }

    public updatePartialCreditNote(noteId: string, updatedValues: Partial<CreditNote>, transaction: Transaction) {
        const creditNoteRef = this.getCreditNoteRef(noteId);
        if (transaction) {
            transaction.update(creditNoteRef, updatedValues);
        } else {
            return updateDoc(creditNoteRef, {
                ...updatedValues,
            });
        }
    }

    public deleteCreditNote(creditNoteId: string) {
        return updateDoc(this.getCreditNoteRef(creditNoteId), { archived: true });
    }

    public createCreditNote(creditNote: CreditNote, options?: { batch: WriteBatch }) {
        const newCreditNoteRef = doc(this.collections.creditNotes(this.usersCompanyId));
        const noteToInsert: CreditNote = { ...creditNote, id: newCreditNoteRef.id };
        if (options?.batch) {
            options.batch.set(newCreditNoteRef, noteToInsert);
        } else {
            setDoc(newCreditNoteRef, noteToInsert);
        }
        return noteToInsert;
    }

    public getCreditNoteWithReceiptNumber(receiptNumber: string, operatingUnitId: OperatingUnit["id"]) {
        return query(
            this.getAllCreditNotes(),
            where("receiptNumber", "==", receiptNumber),
            where("operatingUnitId", "==", operatingUnitId)
        );
    }

    public getCreditNoteWithHighestReceiptNumber(operatingUnitId: OperatingUnit["id"]) {
        return query(
            this.getAllCreditNotes(),
            where("operatingUnitId", "==", operatingUnitId),
            orderBy("receiptNumber", "desc"),
            limit(1)
        );
    }

    public getOfferRef(offerId: string) {
        return doc(this.collections.offers(this.usersCompanyId), offerId);
    }

    public getAllOffers() {
        return query(this.collections.offers(this.usersCompanyId), where("archived", "==", false));
    }

    public updateWholeOffer(offer: Offer) {
        return setDoc(this.getOfferRef(offer.id), offer);
    }

    public updatePartialOffer(offerId: string, updatedValues: Partial<Offer>, transaction?: Transaction) {
        const offerRef = this.getOfferRef(offerId);
        if (transaction) {
            transaction.update(offerRef, updatedValues);
        } else {
            return updateDoc(offerRef, {
                ...updatedValues,
            });
        }
    }

    public deleteOffer(offerId: string) {
        return updateDoc(this.getOfferRef(offerId), { archived: true });
    }

    public createOffer(offer: Offer, options?: { batch: WriteBatch }) {
        const newOfferRef = doc(this.collections.offers(this.usersCompanyId));
        const offerToInsert: Offer = { ...offer, id: newOfferRef.id };
        if (options?.batch) {
            options.batch.set(newOfferRef, offerToInsert);
        } else {
            setDoc(newOfferRef, offerToInsert);
        }
        return offerToInsert;
    }

    public getOfferWithHighestReceiptNumber(operatingUnitId: OperatingUnit["id"]) {
        return query(
            this.getAllOffers(),
            where("operatingUnitId", "==", operatingUnitId),
            orderBy("receiptNumber", "desc"),
            limit(1)
        );
    }

    public getOffersWithReceiptNumber(receiptNumber: string, operatingUnitId: OperatingUnit["id"]) {
        return query(
            this.getAllOffers(),
            where("receiptNumber", "==", receiptNumber),
            where("operatingUnitId", "==", operatingUnitId)
        );
    }

    public copyResource(resource: Resource) {
        return this.insertResource({ ...resource, name: `Kopie von ${resource.name}`, id: "" });
    }

    private async uploadAppendix(
        path: string,
        appendix: File,
        onProgressPercentChange?: (percent: number) => void,
        metadata?: UploadMetadata
    ) {
        const uploadTask = uploadBytesResumable(ref(this.storage(), path), appendix, metadata);

        const [result, error] = await new Promise<[UploadResult, StorageError | undefined]>(resolve => {
            uploadTask.on(
                "state_changed",
                snapshot => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    onProgressPercentChange?.(progress);
                },
                error => resolve([uploadTask.snapshot, error]),
                () => resolve([uploadTask.snapshot, undefined])
            );
        });
        if (error) {
            throw error;
        }
        return result;
    }

    public async uploadBillAppendix(
        billId: string,
        fileName: string,
        appendix: File,
        onProgressPercentChange?: (percent: number) => void
    ) {
        const appendixPath = `companies/${this.usersCompanyId}/bills/${billId}/appendices/${fileName}`;
        await this.uploadAppendix(appendixPath, appendix, onProgressPercentChange);
        // no offline support needed since storage does not work offline anyway
        await updateDoc(this.getBillRef(billId), {
            appendixPaths: arrayUnion(appendixPath),
        });
        return appendixPath;
    }

    public async uploadDeliveryNoteAppendix(
        noteId: string,
        fileName: string,
        appendix: File,
        onProgressPercentChange?: (percent: number) => void
    ) {
        const appendixPath = `companies/${this.usersCompanyId}/deliveryNotes/${noteId}/appendices/${fileName}`;
        await this.uploadAppendix(appendixPath, appendix, onProgressPercentChange);
        // no offline support needed since storage does not work offline anyway
        await updateDoc(this.getDeliveryNoteRef(noteId), {
            appendixPaths: arrayUnion(appendixPath),
        });
        return appendixPath;
    }

    public async uploadOfferAppendix(
        noteId: string,
        fileName: string,
        appendix: File,
        onProgressPercentChange?: (percent: number) => void
    ) {
        const appendixPath = `companies/${this.usersCompanyId}/offers/${noteId}/appendices/${fileName}`;
        await this.uploadAppendix(appendixPath, appendix, onProgressPercentChange);
        // no offline support needed since storage does not work offline anyway
        await updateDoc(this.getOfferRef(noteId), {
            appendixPaths: arrayUnion(appendixPath),
        });
        return appendixPath;
    }

    public async getAllBillAppendices(billId: string) {
        const bill = (await getDoc(this.getBillRef(billId))).data();
        if (!bill) {
            throw new Error("Rechnung nicht gefunden");
        }

        return await Promise.all((bill.appendixPaths ?? []).map(path => getBlob(ref(this.storage(), path))));
    }

    public async getAllDeliveryNoteAppendices(noteId: string) {
        const deliveryNote = (await getDoc(this.getDeliveryNoteRef(noteId))).data();
        if (!deliveryNote) {
            throw new Error("Lieferschein nicht gefunden");
        }

        return await Promise.all((deliveryNote.appendixPaths ?? []).map(path => getBlob(ref(this.storage(), path))));
    }

    public async getAllOfferAppendices(noteId: string) {
        const offer = (await getDoc(this.getOfferRef(noteId))).data();
        if (!offer) {
            throw new Error("Angebot nicht gefunden");
        }

        return await Promise.all((offer.appendixPaths ?? []).map(path => getBlob(ref(this.storage(), path))));
    }

    public getSharingTokens(type: SharingTokenType, customerId: Customer["id"]) {
        return query(
            this.collections.sharingTokens(this.usersCompanyId),
            where("payload.customerId", "==", customerId),
            where("payload.type", "==", type)
        );
    }

    public setSharingToken(sharingToken: SharingToken, options?: { batch?: WriteBatch }) {
        if (options?.batch) {
            options.batch.set(doc(this.collections.sharingTokens(this.usersCompanyId), sharingToken.id), sharingToken);
        } else {
            setDoc(doc(this.collections.sharingTokens(this.usersCompanyId), sharingToken.id), sharingToken);
        }
    }

    public async deleteSharingTokens(
        companyId: AppCompany["id"],
        filter: { customerIds?: Customer["id"][]; types?: SharingTokenType[] },
        batch?: WriteBatch
    ): Promise<{ deletedTokens: number }> {
        const localBatch = batch ?? this.createWriteBatch();

        const constraints = [];
        if ((filter.types?.length ?? 0) > 0) {
            constraints.push(where("payload.type", "in", filter.types));
        }
        if ((filter.customerIds?.length ?? 0) > 0) {
            constraints.push(where("payload.customerId", "in", filter.customerIds));
        }
        const tokensToDeleteDocs = (await getDocs(query(this.collections.sharingTokens(companyId), ...constraints)))
            .docs;

        for (const doc of tokensToDeleteDocs) {
            localBatch.delete(doc.ref);
        }

        localBatch.commit();

        return { deletedTokens: tokensToDeleteDocs.length };
    }

    getAppMeta() {
        return doc(this.firestore(), "meta", "appMeta").withConverter(getModelConverter(AppMeta));
    }

    getUpdateInfos(maxInfos?: number) {
        const constraints: QueryConstraint[] = [where("published", "==", true), orderBy("date", "desc")];
        if (maxInfos) {
            constraints.push(limit(maxInfos));
        }
        return query(this.collections.updateInfos(), ...constraints);
    }

    getSingleUpdateInfoRef(id: string) {
        return doc(this.collections.updateInfos(), id);
    }

    async removeUpdateInfoFromUnseenArray(updateInfoId: string) {
        return await updateDoc(doc(this.collections.appUsers(), this.getCurrentAuthUserUid()), {
            unseenUpdateInfos: arrayRemove(updateInfoId),
        });
    }

    async getFileFromStorage(filePath: string) {
        const fileRef = ref(this.storage(), filePath);
        const metaDataForFile = await getMetadata(fileRef);
        if (!metaDataForFile) {
            return Promise.reject("file-not-found");
        }
        return getBlob(fileRef);
    }

    /**
     * Only for diagnostics
     */
    getUsersCompanyId() {
        return this.usersCompanyId;
    }

    getProjectsRef() {
        return this.collections.projects(this.usersCompanyId);
    }

    getProjectRef(projectId: string) {
        return doc(this.getProjectsRef(), projectId);
    }

    createProject(project: Project) {
        const projectRef = doc(this.getProjectsRef());
        const projectId = projectRef.id;

        setDoc(projectRef, { ...project, id: projectId });
    }

    async deleteProject(projectId: string) {
        const orders = await getDocs(query(this.getAllOrders(true), where("projectId", "==", projectId)));

        const batch = this.createWriteBatch();

        batch.delete(this.getProjectRef(projectId));

        for (const orderDoc of orders.docs) {
            batch.update(orderDoc.ref, { projectId: null });
        }

        batch.commit();
    }

    getProjectOrders(projectId: string): Query<Order> {
        return query(this.getAllOrders(), where("projectId", "==", projectId));
    }

    getProjectRentalOrders(projectId: string): Query<RentalOrder> {
        return query(this.getAllRentalOrders(), where("projectId", "==", projectId));
    }
}

export default Firebase;

type GetTimeTrackingsOrderLike = Pick<Order, "id" | "decoupleTimeTrackings">;
