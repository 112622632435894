import { MachineCounterType } from "./MachineCounterTracking";
import { OperatingUnit } from "./OperatingUnit";
import { IdentifiablePhoto } from "./Order";
import { any, array, boolean, Describe, enums, nullable, number, object, string } from "superstruct";

export enum MachineType {
    TRACTOR = "TRACTOR",
    SELF_DRIVING = "SELF_DRIVING",
    TRAILER = "TRAILER",
    MOUNT = "MOUNT",
    TRUCK = "TRUCK",
    CONTAINER = "CONTAINER",
    SMALL_DEVICE = "SMALL_DEVICE",
    OTHER = "OTHER",
}

export function getAllMachineTypes() {
    return [
        MachineType.TRACTOR,
        MachineType.SELF_DRIVING,
        MachineType.TRAILER,
        MachineType.MOUNT,
        MachineType.TRUCK,
        MachineType.CONTAINER,
        MachineType.SMALL_DEVICE,
        MachineType.OTHER,
    ];
}

export const getMachineTypeName = (type: MachineType) => {
    switch (type) {
        case MachineType.MOUNT:
            return "Anbaugerät";
        case MachineType.SELF_DRIVING:
            return "Selbstfahrer";
        case MachineType.TRACTOR:
            return "Traktor";
        case MachineType.TRAILER:
            return "Anhänger";
        case MachineType.TRUCK:
            return "LKW";
        case MachineType.CONTAINER:
            return "Container";
        case MachineType.SMALL_DEVICE:
            return "Kleingerät";
        case MachineType.OTHER:
            return "Sonstiges";
        default:
            return type;
    }
};

export enum MachinePossessionType {
    LEASING = "Leasing",
    PROPERTY = "Eigentum",
    RENT = "Miete",
    FINANCING = "Finanzierung",
}

export enum MachineUsageUnit {
    NAME = "name",
    VEHICLE_NUMBER = "vehicleNumber",
    TOTAL_HOURS = "totalHours",
    TOTAL_AREA_SQUARE_METERS = "totalAreaSquareMeters",
    TOTAL_TRACKS = "totalTracks",
    TOTAL_TONS = "totalTons",
    TOTAL_CUBIC_METERS = "totalCubicMeters",
    TOTAL_LITER = "totalLiter",
    TOTAL_COST = "totalCost",
}

export enum MachineVolumeUnit {
    LITER = "LITER",
    CUBIC = "CUBIC",
    TONS = "TONS",
    KILO = "KILO",
    PIECES = "PIECES",
    METERS = "METERS",
    HECTARES = "HECTARES",
}

export const getMachineVolumeUnitName = (machineVolumeUnit: MachineVolumeUnit) => {
    switch (machineVolumeUnit) {
        case MachineVolumeUnit.LITER:
            return "Liter";
        case MachineVolumeUnit.CUBIC:
            return "Kubikmeter";
        case MachineVolumeUnit.TONS:
            return "Tonnen";
        case MachineVolumeUnit.KILO:
            return "Kilogramm";
        case MachineVolumeUnit.METERS:
            return "Meter";
        case MachineVolumeUnit.PIECES:
            return "Stück";
        case MachineVolumeUnit.HECTARES:
            return "Hektar";
        default:
            return "Unbek. Einheit";
    }
};

export const getMachinePosessionTypeName = (type: MachinePossessionType) => {
    switch (type) {
        case MachinePossessionType.FINANCING:
            return "Finanzierung";
        case MachinePossessionType.LEASING:
            return "Leasing";
        case MachinePossessionType.RENT:
            return "Miete";
        case MachinePossessionType.PROPERTY:
            return "Eigentum";
    }
};

export interface CostCalculationAssumptions {
    purchasePrice: number | null; // Anschaffungspreis
    usageHoursPerYear: number | null;
    usageYears: number | null;
    residualValue: number | null;
    insurancePerYear: number | null;
    taxPerYear: number | null;
    yearlyRate: number | null;
    repairCostYearly: number | null;
    interestYearly: number | null;
}

export const defaultCostCalculationAssumptions: CostCalculationAssumptions = {
    purchasePrice: 0,
    usageHoursPerYear: 0,
    usageYears: 0,
    residualValue: 0,
    insurancePerYear: 0,
    taxPerYear: 0,
    yearlyRate: 0,
    repairCostYearly: 0,
    interestYearly: 0,
};

export interface RentalPrice {
    id: string;
    unit: MachineCounterType | string;
    pricePerUnit: number;
}

export class Machine {
    // general
    public id: string;
    public internalId: string;
    public name: string;
    public photo: IdentifiablePhoto | null;
    public active: boolean;
    public manufacturer: string;
    public manufacturingYear: number;
    public chassisSerialNumber: string;
    public entranceDate: string | null;
    public vehicleNumber: string;
    public nextInspection: string | null;
    public nextSecurityInspection: string | null;
    public firstRegistration: string | null;
    public owner: string;
    public note: string;
    public posessionType: MachinePossessionType;
    public currentOperatingHours: number;
    public counters: MachineCounterType[];

    // technical
    public type: MachineType;
    public emptyWeightKg: number;
    public maxPayloadKg: number;
    public volume: number;
    public volumeUnit: MachineVolumeUnit;
    public workWidthCm: number;

    // cost
    public costPerHectar: number;
    public costPerUsageHour: number;
    public costPerDrivingHour: number;
    public costCalculationAssumptions: CostCalculationAssumptions | null;

    // rental
    /**
     * Flag whether this machine is available to rent.
     * Is automatically updated by a Firebase Function if the machine changes.
     * If `rentalPrices` contains some items it is `true`, or `false` if `rentalPrices` is empty.
     * @default false
     */
    public readonly isRentable: boolean;
    public rentalPrices: RentalPrice[];

    public trackerId: string | null;

    public archived: boolean;
    public operatingUnitIds: Array<OperatingUnit["id"]>;

    constructor(initialValues?: Partial<Machine>) {
        this.id = initialValues?.id ?? "";
        this.internalId = initialValues?.internalId ?? "";
        this.active = initialValues?.active ?? true;
        this.name = initialValues?.name ?? "";
        this.photo = initialValues?.photo?.imageSrc && initialValues?.photo.storagePath ? initialValues.photo : null;
        this.manufacturer = initialValues?.manufacturer ?? "";
        this.manufacturingYear = initialValues?.manufacturingYear ?? 0;
        this.chassisSerialNumber = initialValues?.chassisSerialNumber ?? "";
        this.entranceDate = initialValues?.entranceDate ?? null;
        this.nextInspection = initialValues?.nextInspection ?? "";
        this.nextSecurityInspection = initialValues?.nextSecurityInspection ?? "";
        this.owner = initialValues?.owner ?? "";
        this.note = initialValues?.note ?? "";
        this.posessionType = initialValues?.posessionType || MachinePossessionType.PROPERTY;
        this.currentOperatingHours = initialValues?.currentOperatingHours ?? 0;
        this.counters = initialValues?.counters ?? [];
        this.firstRegistration = initialValues?.firstRegistration ?? null;
        this.type = initialValues?.type || MachineType.SELF_DRIVING;
        this.emptyWeightKg = initialValues?.emptyWeightKg ?? 0;
        this.volume = initialValues?.volume ?? 0;
        this.volumeUnit = initialValues?.volumeUnit || MachineVolumeUnit.LITER;
        this.workWidthCm = initialValues?.workWidthCm ?? 0;
        this.maxPayloadKg = initialValues?.maxPayloadKg ?? 0;
        this.costPerHectar = initialValues?.costPerHectar ?? 0;
        this.costPerUsageHour = initialValues?.costPerUsageHour ?? 0;
        this.costPerDrivingHour = initialValues?.costPerDrivingHour ?? 0;
        this.vehicleNumber = initialValues?.vehicleNumber ?? "";
        this.costCalculationAssumptions = initialValues?.costCalculationAssumptions ?? null;
        this.isRentable = initialValues?.isRentable ?? (initialValues?.rentalPrices?.length ?? 0) > 0;
        this.rentalPrices = initialValues?.rentalPrices ?? [];
        this.trackerId = initialValues?.trackerId ?? null;
        this.archived = initialValues?.archived ?? false;
        this.operatingUnitIds = initialValues?.operatingUnitIds ?? [];
    }
}

export const MACHINE_TYPES_ELIGIBLE_FOR_FUELING = [
    MachineType.TRACTOR,
    MachineType.SELF_DRIVING,
    MachineType.TRUCK,
    MachineType.OTHER,
];

export const MachineStruct: Describe<Machine> = object({
    id: string(),
    internalId: string(),
    name: string(),
    photo: nullable(object({ storagePath: string(), imageSrc: string() })),
    active: boolean(),
    manufacturer: string(),
    manufacturingYear: number(),
    chassisSerialNumber: string(),
    entranceDate: nullable(string()),
    vehicleNumber: string(),
    nextInspection: nullable(string()),
    nextSecurityInspection: nullable(string()),
    firstRegistration: nullable(string()),
    owner: string(),
    note: string(),
    posessionType: enums(Object.values(MachinePossessionType)),
    currentOperatingHours: number(),
    counters: array(enums(Object.values(MachineCounterType))),
    type: enums(Object.values(MachineType)),
    emptyWeightKg: number(),
    maxPayloadKg: number(),
    volume: number(),
    volumeUnit: enums(Object.values(MachineVolumeUnit)),
    workWidthCm: number(),
    costPerHectar: number(),
    costPerUsageHour: number(),
    costPerDrivingHour: number(),
    costCalculationAssumptions: nullable(
        object({
            purchasePrice: nullable(number()),
            usageHoursPerYear: nullable(number()),
            usageYears: nullable(number()),
            residualValue: nullable(number()),
            insurancePerYear: nullable(number()),
            taxPerYear: nullable(number()),
            yearlyRate: nullable(number()),
            repairCostYearly: nullable(number()),
            interestYearly: nullable(number()),
        })
    ),
    isRentable: boolean(),
    rentalPrices: array(any()),
    archived: boolean(),
    operatingUnitIds: array(string()),
    trackerId: nullable(string()),
});
