import dayjs from "dayjs";
import { Bill } from "./Bill";
import { Receipt } from "./Receipt";

export class BillReminder extends Bill {
    billId: Bill["id"];
    title: string;
    fee: number | null;

    constructor(initialValues?: Partial<BillReminder>) {
        super(initialValues);
        this.id = initialValues?.id ?? "";
        this.billId = initialValues?.billId ?? "";
        this.title = initialValues?.title ?? "";
        this.fee = initialValues?.fee ?? null;
    }

    static fromBill(bill: Bill, initialValues?: Partial<BillReminder>): BillReminder {
        return new BillReminder({
            ...bill,
            billId: bill.id,
            date: dayjs().toISOString(),
            title: DEFAULT_BILL_REMINDER_SETTINGS.title,
            ...initialValues,
        });
    }
}

export interface BillReminderSettings {
    title: string;
    intro: string;
    outro: string;
}
export const DEFAULT_BILL_REMINDER_SETTINGS: BillReminderSettings = {
    title: "Mahnung",
    intro: "",
    outro: "",
};

export function isBillReminder(receipt: Receipt): receipt is BillReminder {
    const receiptToCheck = receipt as Partial<BillReminder>;
    return (
        (typeof receiptToCheck.fee === "number" || receiptToCheck.fee === null) &&
        typeof receiptToCheck.billId === "string"
    );
}
