export class MailSettings {
    offer: ReceiptTypeMailSettings;
    bill: ReceiptTypeMailSettings;
    deliveryNote: ReceiptTypeMailSettings;
    creditNote: ReceiptTypeMailSettings;
    billReminder: ReceiptTypeMailSettings;

    constructor(initialValues?: Partial<MailSettings>) {
        this.offer = initialValues?.offer ?? defaultSetting;
        this.bill = initialValues?.bill ?? defaultSetting;
        this.deliveryNote = initialValues?.deliveryNote ?? defaultSetting;
        this.creditNote = initialValues?.creditNote ?? defaultSetting;
        this.billReminder = initialValues?.billReminder ?? defaultSetting;
    }
}

export interface ReceiptTypeMailSettings {
    subject: string;
    text: string;
    displayName: string;
    replyTo: string;
}

const defaultSetting: ReceiptTypeMailSettings = {
    subject: "",
    text: "",
    displayName: "",
    replyTo: "",
};
