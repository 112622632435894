import dayjs from "dayjs";

export type ChangeHistoryEntryEditor = {
    appUserId: string;
    employeeId: string;
    employeeName: string; // Firstname + Lastname
};

export class ChangeHistoryEntry {
    id: string;
    version: string; // ISO-Datetime;
    editor: ChangeHistoryEntryEditor;

    constructor(initialData?: Partial<ChangeHistoryEntry>) {
        this.id = initialData?.id ?? "";
        this.version = initialData?.version ?? dayjs().toISOString();
        this.editor = {
            appUserId: initialData?.editor?.appUserId ?? "",
            employeeId: initialData?.editor?.employeeId ?? "",
            employeeName: initialData?.editor?.employeeName ?? ""
        };
    }
}
