import { CustomerPaymentInfos, DEFAULT_CUSTOMER_PAYMENT_INFOS } from "./Customer";
import { Receipt } from "./Receipt";

export enum CreditNoteStatus {
    OPEN = "OPEN",
    PAID = "PAID",
}

export const getCreditNoteStatusName = (status: CreditNoteStatus) => {
    switch (status) {
        case CreditNoteStatus.OPEN:
            return "Offen";
        case CreditNoteStatus.PAID:
            return "Bezahlt";
    }
};
export class CreditNote extends Receipt {
    public creditNoteStatus: CreditNoteStatus;
    public paymentInfos: CustomerPaymentInfos;

    constructor(initialValues?: Partial<CreditNote>) {
        super(initialValues);
        this.creditNoteStatus = initialValues?.creditNoteStatus ?? CreditNoteStatus.OPEN;
        this.paymentInfos = {
            ...DEFAULT_CUSTOMER_PAYMENT_INFOS,
            ...initialValues?.paymentInfos,
        };
    }
}

export function isCreditNote(receipt: Receipt): receipt is CreditNote {
    const receiptToCheck = receipt as Partial<CreditNote>;
    return typeof receiptToCheck.creditNoteStatus === "string";
}
