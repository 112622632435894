import { FALLBACK_LOCALE, Locale } from "./Locale";
import { Role } from "./Role";
import { OrderStatus, OrderStatusColors } from "./OrderStatus";
import { AppCompany } from "./AppCompany";
import { Customer } from "./Customer";
import { AdminLevel } from "./adminTools/AdminLevel";
import { Machine } from "./Machine";
import { Employee } from "./Employee";

export const DEFAULT_ORDER_COLORS: OrderStatusColors = {
    [OrderStatus.DRAFT]: "#cccccc",
    [OrderStatus.ASSIGNED]: "#c8efe5",
    [OrderStatus.IN_PROGRESS]: "#34c29f",
    [OrderStatus.DONE]: "#9be29b",
    [OrderStatus.CHECKED]: "#345e9f",
    [OrderStatus.BILLED]: "#fec806",
};

export class AppUser {
    public id: string;
    public email: string;
    public username: string;
    public archived: boolean;
    public orderColors: OrderStatusColors;
    public locale: Locale;
    public unseenUpdateInfos: string[];
    public dispositionConfiguration: DispositionConfiguration;
    public lastSeenAppVersion: AppVersion | null;

    constructor(initialValues?: Partial<AppUser>) {
        this.id = initialValues?.id ?? "";
        this.email = initialValues?.email ?? "";
        this.username = initialValues?.username ?? "";
        this.archived = initialValues?.archived ?? false;
        this.orderColors = { ...DEFAULT_ORDER_COLORS, ...initialValues?.orderColors };
        this.locale = initialValues?.locale ?? FALLBACK_LOCALE;
        this.unseenUpdateInfos = initialValues?.unseenUpdateInfos ?? [];
        this.dispositionConfiguration = initialValues?.dispositionConfiguration ?? {
            hiddenMachineIds: [],
            hiddenEmployeeIds: [],
        };
        this.lastSeenAppVersion = initialValues?.lastSeenAppVersion ?? null;
    }
}

export interface CustomAppUserClaims {
    defaultCompanyId?: AppCompany["id"] | null;
    roles?: CompanyRoles;
    customerIds?: { [companyId: AppCompany["id"]]: Customer["id"] };
    adminLevel?: AdminLevel;
    inactiveCompanyIds?: string[];
}

export type CompanyRoles = { [companyId: string]: Role };
export type CustomerRoles = CompanyRoles;

export interface DispositionConfiguration {
    hiddenMachineIds: Array<Machine["id"]>;
    hiddenEmployeeIds: Array<Employee["id"]>;
}

interface AppVersion {
    major: number;
    minor: number;
    patch: number;
}