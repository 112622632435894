import {PhotoSize} from "@farmact/model/src/model/PhotoSize";
import {getNextSmallerPhotoSize} from "./getNextSmallerPhotoSize";

/**
 * Gets a photo from cloud storage. When a maxSize param is given, it returns the foto in the highest available
 * resolution that's at most the desired one.
 * @param photoPath path to the photo with original size
 * @param fetchPhoto callback to fetch the photo from a storage path
 * @param maxSize desired maximum resolution
 */
export async function getPhotoWithSizeOrSmaller(
    photoPath: string,
    fetchPhoto: (photoPath: string) => Promise<Blob>,
    maxSize: PhotoSize = PhotoSize.ORIGINAL
) {
    let currentSize: PhotoSize | null = maxSize;
    while (currentSize) {
        const currentSizePhotoPath = getPhotoPath(photoPath, currentSize);
        try {
            return fetchPhoto(currentSizePhotoPath);
        } catch (e: any) {
            if (currentSize === PhotoSize.ORIGINAL) {
                currentSize = null;
            } else {
                currentSize = getNextSmallerPhotoSize(currentSize);
            }
        }
    }
    return Promise.reject("photo-not-found");
}

export function getPhotoPath(originalPhotoPath: string, size: PhotoSize) {
    if (size === PhotoSize.ORIGINAL) {
        return originalPhotoPath;
    }
    const pathSegments = originalPhotoPath.split("/");
    const directory = pathSegments.slice(0, -1).join("/");
    const fileName = pathSegments[pathSegments.length - 1].split(".");
    if (fileName.length === 1) {
        return `${directory}/resized/${fileName[0]}_${getSuffixForPhotoSize(size)}`;
    } else {
        return `${directory}/resized/${fileName.slice(0, -1).join(".")}_${getSuffixForPhotoSize(size)}.${
            fileName[fileName.length - 1]
        }`;
    }
}

export function getResizedPhotosPath(originalPhotoPath: string): string {
    const pathSegments = originalPhotoPath.split("/");
    const directory = pathSegments.slice(0, -1).join("/");

    return `${directory}/resized`;
}

// TODO: move to core / utils package
function getSuffixForPhotoSize(size: PhotoSize) {
    switch (size) {
        case PhotoSize.XS:
            return "100x100";
        case PhotoSize.S:
            return "200x200";
        case PhotoSize.M:
            return "400x400";
        case PhotoSize.L:
            return "800x800";
        case PhotoSize.XL:
            return "1600x1600";
        case PhotoSize.XXL:
            return "4000x4000";
        case PhotoSize.ORIGINAL:
            return "";
    }
}
