import { DEFAULT_NOTE_CONTENT_SETTINGS, NoteContentSettings } from "./DeliveryNoteSettings";
import { PhotoSize } from "./PhotoSize";
import { Receipt } from "./Receipt";

export enum DeliveryNoteStatus {
    OPEN = "OPEN",
    APPROVED = "APPROVED",
}

export function getDeliveryStatusName(status: DeliveryNoteStatus) {
    switch (status) {
        case DeliveryNoteStatus.APPROVED:
            return "Genehmigt";
        case DeliveryNoteStatus.OPEN:
            return "Offen";
    }
}

export class DeliveryNote extends Receipt {
    public authorId: string;
    public deliveryNoteStatus: DeliveryNoteStatus;
    public noteContentSettings: NoteContentSettings;
    public createdBillId: string | null;
    public photoExportResolution: PhotoSize;
    /**
     * Whether the delivery note should be sent to Maschinenring.
     */
    public markedForMaschinenring: boolean;
    /**
     * null if the delivery note has never been exported for MR.
     * ISO date string otherwise.
     */
    public exportedToMaschinenringTimestamp: string | null;

    public signature: DeliveryNoteSignature | null;

    constructor(initialValues?: Partial<DeliveryNote>) {
        super(initialValues);
        this.authorId = initialValues?.authorId ?? "";
        this.deliveryNoteStatus = initialValues?.deliveryNoteStatus ?? DeliveryNoteStatus.OPEN;
        this.noteContentSettings = initialValues?.noteContentSettings ?? DEFAULT_NOTE_CONTENT_SETTINGS;
        this.createdBillId = initialValues?.createdBillId ?? null;
        this.photoExportResolution = initialValues?.photoExportResolution ?? PhotoSize.M;
        this.signature = initialValues?.signature ?? null;
        this.exportedToMaschinenringTimestamp = initialValues?.exportedToMaschinenringTimestamp ?? null;
        this.markedForMaschinenring = initialValues?.markedForMaschinenring ?? false;
    }
}

export interface DeliveryNoteSignature {
    signature: string; // Base64 encoded data url
    name: string;
    date: string; // ISO-Datetime
}

export function isDeliveryNote(deliveryNote?: Receipt): deliveryNote is DeliveryNote {
    if(!deliveryNote) {
        return false;
    }
    const deliveryNoteToCheck = deliveryNote as Partial<DeliveryNote>;
    return (
        typeof deliveryNoteToCheck.deliveryNoteStatus === "string" &&
        Object.values(DeliveryNoteStatus).includes(deliveryNoteToCheck.deliveryNoteStatus)
    );
}
