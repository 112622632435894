export const firebaseConfig = {
    apiKey: "AIzaSyAHiMYvFnNVaC3hX30yvThsgU_-2Vv8aEg",
    authDomain: "prod-farmact.firebaseapp.com",
    databaseURL: "https://prod-farmact.firebaseio.com",
    projectId: "prod-farmact",
    storageBucket: "prod-farmact.appspot.com",
    messagingSenderId: "809855630548",
    appId: "1:809855630548:web:ac334dfd51f9bea1c00f41",
    measurementId: "G-Q1XH8RG4ME",
};

export const environment: any = "prod";

export const bugSnagApiKey: string = "b50166bfa8586094d7923060ccd5c810";

export const appVersion = {
    major: 3,
    minor: 14,
    patch: 6,
};

export const stripePublicKey =
    "pk_live_51HEUp0DMi53eMe7D2bFkklkbOqEBEjyeuOwmDVoFlAkMlJ80kFRxFCsJLvXg2ZaPmMDnbe2F1FR3CRUP25Rcv2lN00YfrLwQCr";

export const hotJarId = 2971632;

export const localAddress = "";
