import { DocumentReference, onSnapshot, Query } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { usePageIsVisible } from "../util/customHooks/usePageIsVisible";

export function useDocumentDataFromQueries<T>(
    queries: DocumentReference<T>[],
    deps: React.DependencyList = []
): [T[], boolean, Set<Error>?] {
    const [resultMap, setResultMap] = useState<Map<string, T>>(new Map());
    const [doneLoadingSet, setDoneLoadingSet] = useState(new Set());
    const [errorsSet, setErrorsSet] = useState<Set<Error>>(new Set());

    const isPageVisible = usePageIsVisible();

    useEffect(() => {
        if (!isPageVisible) {
            return;
        }

        const listenerCleanups = new Set<() => void>();
        for (const query of queries) {
            const listener = onSnapshot(
                query,
                snapshot => {
                    setDoneLoadingSet(prevSet => new Set(prevSet.add(snapshot.ref.id)));
                    setResultMap(prevMap => new Map(prevMap).set(snapshot.ref.id, snapshot.data() as T));
                },
                e => {
                    console.error(e, query);
                    setDoneLoadingSet(prevSet => new Set(prevSet.add(query.id)));
                    setErrorsSet(prev => new Set(prev).add(e));
                }
            );
            listenerCleanups.add(listener);
        }
        return () => {
            listenerCleanups.forEach(cleanup => cleanup());
        };
        // DONT put `query` as dependencies! This will cause infinite HTTP requests to Firestore
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...deps, isPageVisible]);

    const isLoading = doneLoadingSet.size < queries.length;

    return [Array.from(resultMap.values()), isLoading, errorsSet];
}

// idea for optimization if needed: store data in ref and return it for the first time only when everything has loaded
export function useCollectionDataFromQueries<T>(
    queries: Query<T>[],
    deps: React.DependencyList = []
): [T[], boolean, Set<Error>?] {
    const [queryResults, setResultMap] = useState(new Array<T[]>(queries.length).fill([]));
    const [loadingList, setLoadingList] = useState(new Array<boolean>(queries.length).fill(true));
    const [errorsSet, setErrorsSet] = useState<Set<Error>>(new Set());

    const isPageVisible = usePageIsVisible();

    useEffect(() => {
        if (!isPageVisible) {
            return;
        }

        const listenerCleanups = new Set<() => void>();
        queries.forEach((query, index) => {
            const listener = onSnapshot(
                query,
                snapshot => {
                    setResultMap(prevMap => {
                        const newMap = [...prevMap];
                        newMap[index] = snapshot.docs.map(doc => doc.data() as T);
                        return newMap;
                    });
                    setLoadingList(prev => {
                        const newLoadedList = [...prev];
                        newLoadedList[index] = false;
                        return newLoadedList;
                    });
                },
                e => {
                    console.error(e, query);
                    setResultMap(prevMap => {
                        const newMap = [...prevMap];
                        newMap[index] = [];
                        return newMap;
                    });
                    setLoadingList(prev => {
                        const newLoadedList = [...prev];
                        newLoadedList[index] = false;
                        return newLoadedList;
                    });
                    setErrorsSet(prev => new Set(prev).add(e));
                }
            );
            listenerCleanups.add(listener);
        });
        return () => {
            listenerCleanups.forEach(cleanup => cleanup());
        };
        // DONT put `query` as dependencies! This will cause infinite HTTP requests to Firestore
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...deps, isPageVisible]);

    const isLoading = loadingList.some(result => result);

    return [queryResults.flatMap(queryResult => queryResult), isLoading, errorsSet];
}

export function useCollectionData<T>(
    query?: Query<T>,
    deps: React.DependencyList = []
): [T[], boolean, Error | null, boolean] {
    const [collection, setCollection] = useState<T[]>([]);
    const [isLoading, setIsLoading] = useState(!!query);
    const [error, setError] = useState<Error | null>(null);
    const [resultIsFromActualQuery, setResultIsFromActualQuery] = useState(false);

    const [previousQuery, setPreviousQuery] = useState<Query<T> | undefined>(undefined);
    if (query !== undefined && previousQuery === undefined) {
        setPreviousQuery(query);
        setIsLoading(true);
    } else if (query === undefined && previousQuery !== undefined) {
        setPreviousQuery(undefined);
        setIsLoading(false);
        setCollection([]);
    }

    const isPageVisible = usePageIsVisible();

    useEffect(() => {
        if (!isPageVisible) {
            return;
        }
        if (!query) {
            return;
        }

        setIsLoading(true);
        return onSnapshot(
            query,
            snapshot => {
                setCollection(snapshot.docs.map(doc => doc.data() as T));
                setIsLoading(false);
                setError(null);
                setResultIsFromActualQuery(true);
            },
            e => {
                console.error(e, query);
                setIsLoading(false);
                setError(e);
                setResultIsFromActualQuery(false);
            }
        );
        // DONT put `query` as dependencies! This will cause infinite HTTP requests to Firestore
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...deps, isPageVisible]);

    return [collection, isLoading, error, resultIsFromActualQuery];
}

export function useDocumentData<T>(
    query?: DocumentReference<T>,
    deps: React.DependencyList = []
): [T | undefined, boolean, Error?] {
    const [document, setDocument] = useState<T>();
    const [isLoading, setIsLoading] = useState(!!query);
    const [error, setError] = useState<Error>();

    const queryRef = useRef<DocumentReference<T> | undefined>(query);
    queryRef.current = query;

    const [previousQuery, setPreviousQuery] = useState<DocumentReference<T> | undefined>(undefined);
    if (query !== undefined && previousQuery === undefined) {
        setPreviousQuery(query);
        setIsLoading(true);
    } else if (query === undefined && previousQuery !== undefined) {
        setPreviousQuery(undefined);
        setIsLoading(false);
        setDocument(undefined);
    }

    const isPageVisible = usePageIsVisible();

    useEffect(() => {
        if (!isPageVisible) {
            return;
        }
        if (!query) {
            return;
        }

        setIsLoading(true);
        return onSnapshot(
            query,
            snapshot => {
                if (query?.id === queryRef.current?.id) {
                    setDocument(snapshot.data() as T);
                    setError(undefined);
                    setIsLoading(false);
                }
            },
            e => {
                console.error(e, query);
                setError(e);
                setIsLoading(false);
            }
        );
        // DONT put `query` as dependencies! This will cause infinite HTTP requests to Firestore
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...deps, isPageVisible]);

    return [document, isLoading, error];
}
