import { Machine } from "@farmact/model/src/model/Machine";
import { isMachineCounterType, MachineCounterType } from "@farmact/model/src/model/MachineCounterTracking";
import {
    AnyRentalOrderPriceTracking,
    RentalOrder,
    RentalOrderCustomCounterPriceTracking,
    RentalOrderMachineCounterPriceTracking,
    RentalOrderPriceTracking,
    RentalOrderPriceTrackingType,
} from "@farmact/model/src/model/RentalOrder";
import { IOrganizationContext } from "../components/organization/context/OrganizationContext";
import { AppCompany } from "@farmact/model/src/model/AppCompany";
import { OrderStatus } from "@farmact/model/src/model/OrderStatus";
import { PLACEHOLDER_MANUALLY_BILLED } from "@farmact/model/src/model/Bill";

export function createRentalOrderPriceTrackings(machines: Machine[]): AnyRentalOrderPriceTracking[] {
    const priceTrackings: AnyRentalOrderPriceTracking[] = [];

    for (const machine of machines) {
        for (const rentalPrice of machine.rentalPrices) {
            const priceTracking: RentalOrderPriceTracking = {
                id: rentalPrice.id,
                machineId: machine.id,
                pricePerUnit: rentalPrice.pricePerUnit,
                type: RentalOrderPriceTrackingType.CUSTOM_COUNTER,
            };

            if (isMachineCounterType(rentalPrice.unit)) {
                priceTrackings.push({
                    ...priceTracking,
                    type: RentalOrderPriceTrackingType.MACHINE_COUNTER,
                    machineCounterType: rentalPrice.unit,
                    startEmployeeId: null,
                    startDateTime: null,
                    startValue: null,
                    endEmployeeId: null,
                    endDateTime: null,
                    endValue: null,
                } as RentalOrderMachineCounterPriceTracking);
            } else {
                priceTrackings.push({
                    ...priceTracking,
                    type: RentalOrderPriceTrackingType.CUSTOM_COUNTER,
                    unit: rentalPrice.unit,
                    employeeId: null,
                    value: null,
                } as RentalOrderCustomCounterPriceTracking);
            }
        }
    }

    return priceTrackings;
}

type RentalOrderPriceTrackingsByMachineGroup<T extends AnyRentalOrderPriceTracking> = Map<Machine["id"], T[]>;

export function groupRentalOrderPriceTrackingsByMachine<T extends AnyRentalOrderPriceTracking>(
    priceTrackings: T[]
): RentalOrderPriceTrackingsByMachineGroup<T> {
    const groups: RentalOrderPriceTrackingsByMachineGroup<T> = new Map();

    for (const priceTracking of priceTrackings) {
        groups.set(priceTracking.machineId, [...(groups.get(priceTracking.machineId) ?? []), priceTracking]);
    }

    return groups;
}

/**
 * Merge all given price trackings into a single array.
 * Note that later price trackings override those that came earlier
 */
export function mergeRentalOrderPriceTrackings(
    ...list: Array<AnyRentalOrderPriceTracking[]>
): AnyRentalOrderPriceTracking[] {
    const assoc: Record<AnyRentalOrderPriceTracking["id"], AnyRentalOrderPriceTracking> = {};

    for (const priceTrackings of list) {
        for (const priceTracking of priceTrackings) {
            assoc[priceTracking.id] = priceTracking;
        }
    }

    return Object.values(assoc);
}

export function getRentalOrderPriceTrackingAmount(priceTracking: AnyRentalOrderPriceTracking): number {
    if (priceTracking.type === RentalOrderPriceTrackingType.CUSTOM_COUNTER) {
        return priceTracking.value ?? 0;
    }

    return Math.abs((priceTracking.endValue ?? 0) - (priceTracking.startValue ?? 0));
}

export function getMachineCounterTypesFromRentalOrderPriceTrackings(rentalOrder: RentalOrder): MachineCounterType[] {
    const priceTrackingsMachine = rentalOrder.machinePriceTrackings.filter(
        (priceTracking): priceTracking is RentalOrderMachineCounterPriceTracking => {
            return priceTracking.type === RentalOrderPriceTrackingType.MACHINE_COUNTER;
        }
    );

    return priceTrackingsMachine.map(priceTracking => {
        return priceTracking.machineCounterType;
    });
}

export function getMachineCounterTypesFromRentalOrderMachines(
    rentalOrder: RentalOrder,
    context: Pick<IOrganizationContext, "machines">
): MachineCounterType[] {
    const usedMachines = context.machines.filter(machine => {
        return rentalOrder.machineIds.includes(machine.id);
    });

    return usedMachines.flatMap(machine => {
        return machine.counters;
    });
}

interface RentalOrderViewConfiguration {
    showMarkAsDone: boolean;
    showMarkAsChecked: boolean;
    //
    showMarkAsBilled: boolean;
    showUndoMarkAsBilled: boolean;
    //
    showCreateBill: boolean;
    showViewBill: boolean;
    //
    showCreateDeliveryNote: boolean;
    showViewDeliveryNote: boolean;
}

interface GetRentalOrderViewConfigurationParams {
    appCompany: AppCompany | undefined;
}

export function getRentalOrderViewConfiguration(
    rentalOrder: RentalOrder,
    params: GetRentalOrderViewConfigurationParams
): RentalOrderViewConfiguration {
    const { appCompany } = params;

    const enableCheckedOrderStatus = !!appCompany?.settings.enableCheckedOrderStatus;

    const statusWhereDeliveryNoteCanBeCreated = [OrderStatus.DONE, OrderStatus.CHECKED, OrderStatus.BILLED];

    const showMarkAsDone = rentalOrder.status === OrderStatus.CHECKED;
    const showMarkAsChecked = enableCheckedOrderStatus && rentalOrder.status === OrderStatus.DONE;

    const showMarkAsBilled = enableCheckedOrderStatus
        ? rentalOrder.status === OrderStatus.CHECKED
        : rentalOrder.status === OrderStatus.DONE;
    const showUndoMarkAsBilled =
        rentalOrder.status === OrderStatus.BILLED && rentalOrder.billId === PLACEHOLDER_MANUALLY_BILLED;

    const showCreateBill = rentalOrder.billId === null;
    const showViewBill = rentalOrder.billId !== null && rentalOrder.billId !== PLACEHOLDER_MANUALLY_BILLED;

    const showCreateDeliveryNote =
        statusWhereDeliveryNoteCanBeCreated.includes(rentalOrder.status) && rentalOrder.deliveryNoteId === null;
    const showViewDeliveryNote = rentalOrder.deliveryNoteId !== null;

    return {
        showMarkAsDone,
        showMarkAsChecked,
        //
        showMarkAsBilled,
        showUndoMarkAsBilled,
        //
        showCreateBill,
        showViewBill,
        //
        showCreateDeliveryNote,
        showViewDeliveryNote,
    };
}
