import { AppCompany } from "./AppCompany";
import { Customer } from "./Customer";

export class SharingToken {
    public id: string;
    /**
     * `null` value indicates that the token remains valid indefinitely.
     */
    public validUntil: string | null;
    public payload: AnySharingTokenPayload;

    constructor(initialValues?: Partial<SharingToken>) {
        this.id = initialValues?.id ?? "";
        this.validUntil = initialValues?.validUntil ?? null;
        this.payload = initialValues?.payload ?? { type: SharingTokenType.UNKNOWN };
    }
}

export enum SharingTokenType {
    ORDERS_PROGRESS = "ORDERS_PROGRESS",
    MAP_STRUCTURES = "MAP_STRUCTURES",
    UNKNOWN = "UNKNOWN",
}

export interface OrdersSharingTokenPayload {
    type: SharingTokenType.ORDERS_PROGRESS;
    companyId: AppCompany["id"];
    customerId: Customer["id"];
}

export interface MapStructuresSharingTokenPayload {
    type: SharingTokenType.MAP_STRUCTURES;
    companyId: AppCompany["id"];
    customerId: Customer["id"];
}

export interface UnknownSharingTokenPayload {
    type: SharingTokenType.UNKNOWN;
}

export type AnySharingTokenPayload = OrdersSharingTokenPayload | MapStructuresSharingTokenPayload | UnknownSharingTokenPayload;
