import { Track } from "./Track";
import { FARMACT_DEFAULT_LOCATION } from "../constants/farmActDefaultLocation";
import { BoundingBox } from "./BoundingBox";

export class TrackMeta {
    public id: string;
    public boundingBox: BoundingBox;
    public archived: boolean;
    public customerId: string;
    public name: string;
    public color: string;
    /**
     * length in meters
     */
    public length: number;

    constructor(initialValues?: Partial<TrackMeta>) {
        this.id = initialValues?.id ?? "";
        this.archived = initialValues?.archived ?? false;
        this.customerId = initialValues?.customerId ?? "";
        this.name = initialValues?.name ?? "";
        this.color = initialValues?.color ?? Track.DEFAULT_COLOR;
        this.boundingBox = initialValues?.boundingBox ?? { sw: FARMACT_DEFAULT_LOCATION, ne: FARMACT_DEFAULT_LOCATION };
        this.length = initialValues?.length ?? 0;
    }

    public static fromTrack(track: Track) {
        return new TrackMeta({ ...track, boundingBox: BoundingBox.fromShape(track.shape) });
    }
}

export function isTrackMeta(data: any): data is TrackMeta {
    return typeof data.length === "number" && typeof data.boundingBox === "object";
}

export function constructTrackMetaUpdateFromTrackUpdate(trackUpdate: Partial<Track>): Partial<TrackMeta> {
    const update: Partial<TrackMeta> = {};
    if (trackUpdate.length !== undefined) {
        update.length = trackUpdate.length;
    }
    if (trackUpdate.name !== undefined) {
        update.name = trackUpdate.name;
    }
    if (trackUpdate.shape !== undefined) {
        update.boundingBox = BoundingBox.fromShape(trackUpdate.shape);
    }
    if (trackUpdate.archived !== undefined) {
        update.archived = trackUpdate.archived;
    }
    if (trackUpdate.color !== undefined) {
        update.color = trackUpdate.color;
    }
    if (trackUpdate.customerId !== undefined) {
        update.customerId = trackUpdate.customerId;
    }
    return update;
}
