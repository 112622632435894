import { computeLength } from "spherical-geometry-js";
import { array, boolean, Describe, number, object, string } from "superstruct";
import { Vertex, VertexStruct } from "./Field";

export class Track {
    public static DEFAULT_COLOR = "#39b54a";

    public id: string;
    public archived: boolean;
    public customerId: string;
    public shape: Vertex[];
    public name: string;
    public color: string;
    /**
     * length in meters
     */
    public length: number;

    constructor(initialValues?: Partial<Track>) {
        this.id = initialValues?.id ?? "";
        this.archived = initialValues?.archived ?? false;
        this.customerId = initialValues?.customerId ?? "";
        this.shape = initialValues?.shape ?? [];
        this.name = initialValues?.name ?? "";
        this.color = initialValues?.color ?? Track.DEFAULT_COLOR;
        this.length = initialValues?.length || (this.shape.length > 1 ? computeLength(this.shape) : 0);
    }
}

export const TrackStruct = object({
    id: string(),
    archived: boolean(),
    customerId: string(),
    shape: array(VertexStruct),
    name: string(),
    color: string(),
    length: number()
}) satisfies Describe<Track>;
