import { FARMACT_DEFAULT_LOCATION } from "../constants/farmActDefaultLocation";
import Field from "./Field";
import { BoundingBox } from "./BoundingBox";

export class FieldMeta {
    public id: string;
    public boundingBox: BoundingBox;
    public name: string;
    public color: string;
    public other: string;
    public crop: string;
    public cropBefore: string;
    public customerId: string;
    public archived: boolean;
    /**
     * area in hectares
     */
    public areaHa: number;

    constructor(initialValues?: Partial<FieldMeta>) {
        this.id = initialValues?.id ?? "";
        this.name = initialValues?.name ?? "";
        this.color = initialValues?.color ?? Field.DEFAULT_COLOR;
        this.other = initialValues?.other ?? "";
        this.customerId = initialValues?.customerId ?? "";
        this.crop = initialValues?.crop ?? "";
        this.cropBefore = initialValues?.cropBefore ?? "";
        this.archived = initialValues?.archived ?? false;
        this.boundingBox = initialValues?.boundingBox ?? { sw: FARMACT_DEFAULT_LOCATION, ne: FARMACT_DEFAULT_LOCATION };
        this.areaHa = initialValues?.areaHa ?? 0;
    }

    public static fromField(field: Field) {
        return new FieldMeta({ ...field, boundingBox: BoundingBox.fromShape(field.shape) });
    }
}

export function constructFieldMetaUpdateFromFieldUpdate(fieldUpdate: Partial<Field>): Partial<FieldMeta> {
    const update: Partial<FieldMeta> = {};

    if (fieldUpdate.areaHa !== undefined) {
        update.areaHa = fieldUpdate.areaHa;
    }
    if (fieldUpdate.name !== undefined) {
        update.name = fieldUpdate.name;
    }
    if (fieldUpdate.archived !== undefined) {
        update.archived = fieldUpdate.archived;
    }
    if (fieldUpdate.color !== undefined) {
        update.color = fieldUpdate.color;
    }
    if (fieldUpdate.crop !== undefined) {
        update.crop = fieldUpdate.crop;
    }
    if (fieldUpdate.cropBefore !== undefined) {
        update.cropBefore = fieldUpdate.cropBefore;
    }
    if (fieldUpdate.other) {
        update.other = fieldUpdate.other;
    }
    if (fieldUpdate.customerId !== undefined) {
        update.customerId = fieldUpdate.customerId;
    }
    return update;
}

export function isFieldMeta(data: any): data is FieldMeta {
    return typeof data.cropBefore === "string" && typeof data.boundingBox === "object";
}
