import { PhotoSize } from "@farmact/model/src/model/PhotoSize";

export function getNextSmallerPhotoSize(size: PhotoSize) {
    switch (size) {
        case PhotoSize.XS:
            return PhotoSize.ORIGINAL;
        case PhotoSize.S:
            return PhotoSize.XS;
        case PhotoSize.M:
            return PhotoSize.S;
        case PhotoSize.L:
            return PhotoSize.M;
        case PhotoSize.XL:
            return PhotoSize.L;
        case PhotoSize.XXL:
            return PhotoSize.XL;
        case PhotoSize.ORIGINAL:
            return PhotoSize.XL;
    }
}
