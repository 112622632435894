import { computeArea } from "spherical-geometry-js";
import { Vertex } from "./Field";

export class AreaMeasurement {
    public static DEFAULT_COLOR = "#39b54a";

    id: string;
    name: string;
    color: string; // any valid css color format (e.g. hex, rgb etc)
    shape: Vertex[];
    areaHa: number;

    constructor(initialValues?: Partial<AreaMeasurement>) {
        this.id = initialValues?.id ?? "";
        this.name = initialValues?.name ?? "";
        this.color = initialValues?.color ?? AreaMeasurement.DEFAULT_COLOR;
        this.shape = initialValues?.shape ?? [];
        this.areaHa = initialValues?.areaHa ?? computeArea(initialValues?.shape ?? []) / 10000;
    }
}
