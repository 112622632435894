import { OperatingUnit } from "./OperatingUnit";
import { Receipt } from "./Receipt";
import { ReceiptType } from "./ReceiptType";
import dayjs from "dayjs";

export class MailTrace {
    public id: string;
    /**
     * UNIX timestamp in seconds.
     */
    public requestTimestamp: number;
    /**
     * UNIX timestamp in seconds.
     */
    public sentTimestamp: number | null;
    public sender: string;
    public recipients: string[];
    public ccs: string[];
    public bccs: string[];
    public subject: string;
    public status: MailDeliveryStatus;
    public service: MailServiceType;
    public attachments: string[];
    public operatingUnitId: OperatingUnit["id"];
    public receiptId: Receipt["id"] | null;
    public receiptType: ReceiptType | null;

    constructor(initialValues?: Partial<MailTrace>) {
        this.id = initialValues?.id ?? "";
        this.requestTimestamp = initialValues?.requestTimestamp ?? dayjs().unix();
        this.sentTimestamp = initialValues?.sentTimestamp ?? null;
        this.sender = initialValues?.sender ?? "";
        this.recipients = initialValues?.recipients ?? [];
        this.ccs = initialValues?.ccs ?? [];
        this.bccs = initialValues?.bccs ?? [];
        this.subject = initialValues?.subject ?? "";
        this.status = initialValues?.status ?? MailDeliveryStatus.UNKNOWN;
        this.service = initialValues?.service ?? MailServiceType.UNKNOWN;
        this.attachments = initialValues?.attachments ?? [];
        this.operatingUnitId = initialValues?.operatingUnitId ?? "";
        this.receiptId = initialValues?.receiptId ?? null;
        this.receiptType = initialValues?.receiptType ?? null;
    }
}

export enum MailDeliveryStatus {
    UNKNOWN = "UNKNOWN",
    CREATED = "CREATED",
    ASSEMBLED = "ASSEMBLED",
    SENT = "SENT",
    DELIVERED = "DELIVERED",
    UNDELIVERABLE = "UNDELIVERABLE",
    ERROR = "ERROR",
}

export enum MailServiceType {
    UNKNOWN = "UNKNOWN",
    SMTP = "SMTP",
    SES = "SES",
}
