import { Describe, object, string, number, boolean, nullable, array } from "superstruct";
import { OperatingUnit } from "./OperatingUnit";
import { Resource } from "./Resource";

export class GasStation {
    public id: string;
    public name: string;
    public address: string;
    public capacityLiters: number | null;
    public currentLevelEstimate: number | null;
    public archived: boolean;
    public resourceId: Resource["id"] | null;
    public operatingUnitIds: Array<OperatingUnit["id"]>;

    constructor(initialValues?: Partial<GasStation>) {
        this.id = initialValues?.id ?? "";
        this.name = initialValues?.name ?? "";
        this.address = initialValues?.address ?? "";
        this.capacityLiters = initialValues?.capacityLiters ?? null;
        this.currentLevelEstimate = initialValues?.currentLevelEstimate ?? null;
        this.archived = initialValues?.archived ?? false;
        this.resourceId = initialValues?.resourceId ?? null;
        this.operatingUnitIds = initialValues?.operatingUnitIds ?? [];
    }
}

export const GasStationStruct: Describe<GasStation> = object({
    id: string(),
    name: string(),
    address: string(),
    capacityLiters: nullable(number()),
    currentLevelEstimate: nullable(number()),
    archived: boolean(),
    resourceId: nullable(string()),
    operatingUnitIds: array(string()),
});
