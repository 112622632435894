import { Bill } from "../Bill";
import { ChangeHistoryEntry } from "./ChangeHistoryEntry";

export enum BillChangeCategory {
    CREATED = "CREATED",
    UPDATED = "UPDATED",
    DELETED = "DELETED",
}

export class BillChangeHistoryEntry extends ChangeHistoryEntry {
    snapshot: Bill;
    category: BillChangeCategory;

    constructor(initialData?: Partial<BillChangeHistoryEntry>) {
        super(initialData);
        this.snapshot = new Bill(initialData?.snapshot ?? {});
        this.category = initialData?.category ?? BillChangeCategory.UPDATED;
    }
}
