import { v4 } from "uuid";

export class Project {
    id: string;
    name: string;
    customerId: string;
    note: string | null;
    createdAt: string; // ISO datetime

    constructor(initialValues?: Partial<Project>) {
        this.id = initialValues?.id ?? v4();
        this.name = initialValues?.name ?? "";
        this.customerId = initialValues?.customerId ?? "";
        this.note = initialValues?.note ?? null;
        this.createdAt = initialValues?.createdAt ?? new Date().toISOString();
    }
}
