import { ReceiptSettings, ReceiptSettingsType } from "./ReceiptSettings";
import dayjs from "dayjs";

export class CreditNoteSettings extends ReceiptSettings {
    public type = ReceiptSettingsType.CREDIT_NOTE;
    constructor(initialValues?: Partial<CreditNoteSettings>) {
        super(initialValues);
        this.prefix = initialValues?.prefix ?? `GU-${dayjs().year()}`;
    }
}
